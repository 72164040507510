import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { equals } from 'ramda'

import JSONEditor from 'jsoneditor'
import 'jsoneditor/dist/jsoneditor.css'

import './style.css'

class JSONEditorComponent extends Component {
  initJsonEditor () {
    const { defaultMode = 'tree', defaultModes = ['code', 'tree'], onChange, onEditable, editablePaths = [], isFullEditable, schema } = this.props
    const options = {
      modes: defaultModes,
      mode: defaultMode,
      name: 'root',
      showErrorTable: ['text', 'preview', 'code'],
      enableSort: false,
      enableTransform: false,
      schema,
      allowSchemaSuggestions: true,
      onModeChange: (newMode, oldMode) => {
        if (newMode === 'tree' || newMode === 'view') {
          this.jsoneditor.expandAll()
        }
      },
      onChange: () => {
        if (typeof onChange === 'function') {
          try {
            const json = this.jsoneditor.get()
            onChange({ okay: true, json })
          } catch (e) {
            onChange({ okay: false, errorMessage: e.message })
          }
        }
      },
      onEditable: node => {
        if (typeof onEditable === 'function') {
          return onEditable()
        }
        // Allow full editing in "code" mode or if isFullEditable = true
        if ((this.jsoneditor && this.jsoneditor.options.mode === 'code') || isFullEditable) {
          return true
        }
        if (node.path) {
          const currentPath = node.path.join('.')
          // Check if the current path starts with any of the provided editable paths
          const isEditable = editablePaths.some(path => currentPath.startsWith(path))
          if (isEditable) {
            return true
          }
        }
        // Disable editing for other paths
        return false
      },
      onValidationError: e => {
        e.forEach(err => {
          if (err.type === 'validation') {
            onChange({ okay: false, errorMessage: e.message })
          }
        })
      },
    }

    this.jsoneditor = new JSONEditor(this.container, options)
    this.jsoneditor.set(this.props.json || {})
    if (defaultMode === 'view' || defaultMode === 'tree') {
      this.jsoneditor.expandAll()
    }
  }
  componentDidMount () {
    this.initJsonEditor()
  }

  componentWillUnmount () {
    if (this.jsoneditor) {
      this.jsoneditor.destroy()
    }
  }

  componentDidUpdate (prevProps) {
    const { json, isFullEditable, editablePaths } = this.props
    // Only update JSON if the json prop has changed, and it’s not due to editor-triggered changes
    if (
      json && !equals(prevProps.json, json) && !equals(json, this.jsoneditor.get())
    ) {
      this.jsoneditor.update(json)
      this.triggerOnChange(json) // Trigger onChange manually when JSON updates programmatically
    }
    if (
      prevProps.isFullEditable !== isFullEditable ||
      !equals(prevProps.editablePaths, editablePaths)
    ) {
      // Recreate JSONEditor instance to apply new editable paths and permissions
      if (this.jsoneditor) {
        this.jsoneditor.destroy()
      }
      this.initJsonEditor()
    }
  }

  // New method to trigger onChange manually
  triggerOnChange (json) {
    const { onChange } = this.props
    try {
      if (typeof onChange === 'function') {
        onChange({ okay: true, json })
      }
    } catch (e) {
      if (typeof onChange === 'function') {
        onChange({ okay: false, errorMessage: e.message })
      }
    }
  }

  render () {
    return (
      <div className="jsoneditor-react-container" ref={elem => { this.container = elem }} />
    )
  }
}

JSONEditorComponent.propTypes = {
  schema: PropTypes.object,
  defaultMode: PropTypes.oneOf(['code', 'tree']),
  defaultModes: PropTypes.oneOf(['code', 'tree']),
  onChange: PropTypes.func,
  onEditable: PropTypes.func,
  editablePaths: PropTypes.array,
  isFullEditable: PropTypes.bool,
  json: PropTypes.object,
}

export default JSONEditorComponent
