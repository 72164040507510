import { Map } from 'immutable'
import { storageId } from 'config'
import { getDefaultPagination } from '../utils'
import { getDefaultDAEventsFilters, getDefaultFilterString } from 'utils/roverEvents'

const getInitialUserSettings = () => {
  const userSettingsFromLocalStorage = localStorage.getItem(storageId.settings)
  return !userSettingsFromLocalStorage
    ? {
      projects: {
        sortBy: 'updated',
        order: 'desc',
      },
    }
    : JSON.parse(userSettingsFromLocalStorage)
}

export const INITIAL_STATE = Map({
  constants: {
    industries: [],
    countries: [],
    countryToCode: {},
    regions: {},
    timeZones: [],
    tags: [],
    antennas: [],
    antennasOptions: [],
  },
  daEventTypes: [],
  checkListSteps: [],
  daIssueTypes: [],
  systemTypes: [],
  constantsLoaded: false,
  getConstantsIsLoading: false,
  getAllSystemTypesIsLoading: false,
  userSettings: getInitialUserSettings(),

  logs: [],
  logsPagination: getDefaultPagination(),
  getLogsIsLoading: false,

  daEvents: [],
  allDaEvents: [],
  daEventsPagination: getDefaultPagination({
    order: 'desc',
    order_by: 'timestamp',
    filter: getDefaultFilterString(getDefaultDAEventsFilters([])),
  }),
  getDAEventsIsLoading: false,

  daRequestEvents: [],
  allDaRequestEvents: [],
  daRequestEventsPagination: getDefaultPagination({
    order: 'desc',
    order_by: 'status',
    filter: 'status=open',
  }),
  getDARequestEventsIsLoading: false,

  rentEvents: [],
  allRentEvents: [],
  rentEventsPagination: getDefaultPagination({
    order: 'desc',
    order_by: 'timestamp',
  }),
  getRentEventsIsLoading: false,

  notifications: [],
  notificationsPagination: getDefaultPagination(),
  getNotificationsIsLoading: false,

  componentTypes: [],
  propertyTypes: [],
  comments: [],
  sales_invoices: [],
  sales_orders: [],
  purchase_orders: [],
  customerTypes: [],
  isLoadingULData: false,

  recallTypes: [],

  projects: [],
  isLoadingProjects: false,
  headerHeight: 106,
  headerWidth: undefined,

  releases: {},
  isLoadingReleases: {},
  products: [],

  rmaStatuses: [],
  getRMAStatusesIsLoading: false,

  systemTypeStatuses: [],
  getSystemTypeStatusesIsLoading: false,

  workListsByRoverEvent: {},
  getWorkListsIsLoading: false,
})
