import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  getRecalls: ['search', 'pageSize', 'page', 'orderBy', 'order'],
  getRecallsLoading: [],
  getRecallsSuccess: ['recalls', 'pagination'],
  getRecallsFailure: [],

  createRecall: ['data', 'onSuccess'],
  createRecallLoading: [],
  createRecallSuccess: ['recall', 'recallEvents'],
  createRecallFailure: [],

  editRecall: ['recallId', 'data', 'onSuccess'],
  editRecallLoading: ['recallId'],
  editRecallSuccess: ['recallId', 'recall'],
  editRecallFailure: ['recallId'],

  getRecallEvents: ['recallId'],
  getRecallEventsLoading: ['recallId'],
  getRecallEventsSuccess: ['recallId', 'recallEvents'],
  getRecallEventsFailure: ['recallId'],

  createRecallEvent: ['recallId', 'data', 'onSuccess'],
  createRecallEventLoading: [],
  createRecallEventSuccess: ['recallId', 'recallEvent'],
  createRecallEventFailure: [],

  publishRecall: ['recallId', 'data', 'onSuccess'],
  publishRecallLoading: [],
  publishRecallSuccess: ['recall'],
  publishRecallFailure: [],

  editRecallEvent: ['recallId', 'recallEventId', 'data', 'onSuccess'],
  editRecallEventLoading: ['recallEventId'],
  editRecallEventSuccess: ['recallId', 'recallEventId', 'recallEvent'],
  editRecallEventFailure: ['recallEventId'],

  deleteRecallEvent: ['recallId', 'recallEventId', 'onSuccess'],
  deleteRecallEventLoading: ['recallEventId'],
  deleteRecallEventSuccess: ['recallId', 'recallEventId'],
  deleteRecallEventFailure: ['recallEventId'],

  addRecallAttachments: ['eventId', 'eventType', 'attachments', 'onSuccess'],
  addRecallAttachmentsLoading: [],
  addRecallAttachmentsSuccess: ['eventId', 'eventType', 'attachments'],
  addRecallAttachmentsFailure: [],

  deleteRecallAttachments: ['eventId', 'eventType', 'attachmentIds', 'onSuccess'],
  deleteRecallAttachmentsLoading: ['eventId'],
  deleteRecallAttachmentsSuccess: ['eventId', 'eventType', 'attachmentIds'],
  deleteRecallAttachmentsFailure: ['eventId'],

  selectRecall: ['recallId'],
  selectRecallLoading: [],
  selectRecallSuccess: ['recall'],
  selectRecallFailure: [],

  deselectRecall: [],
})

export const RecallsTypes = Types
export default Creators
