import { toast } from 'react-toastify'
import { path } from 'ramda'
import i18n from 'i18n'
import { all, join, call } from 'redux-saga/effects'
import axios from 'utils/axios'

/**
 * Returns authentification header
 * @param {String} authToken
 */
export const getAuthentificationHeader = authToken => {
  return { Authorization: `Bearer ${authToken}` }
}

export function isResultSuccessful (result) {
  const { okay, status: statusCode, statusText, data } = result
  return ((okay || statusText === 'OK' || data.message === 'OK') && statusCode >= 200 && statusCode <= 299)
}

const getRecursiveMessage = messages => {
  if (typeof messages === 'object') {
    return Object.keys(messages).reduce((message, key) => {
      const keyedMessage = messages[key]
      return `${message}\n${key}${Array.isArray(keyedMessage) ? `: ${keyedMessage.join(', ')}` : ` -> ${getRecursiveMessage(keyedMessage)}`}`
    }
    , '')
  }
  return messages || ''
}

export function getErrorMessage (e, networkError) {
  const errorMessage = e && e.message
  if (errorMessage === 'Network Error') return networkError
  const backendMessage = path(['response', 'data', 'message'], e)
  if (typeof backendMessage === 'object') {
    return getRecursiveMessage(backendMessage)
  }
  if (typeof backendMessage === 'string') {
    return backendMessage
  }
  return (e.message || e).toString()
}

export function showErrorMessage (e, networkError) {
  const errorMessage = getErrorMessage(e, networkError) || ''
  if (errorMessage !== 'Invalid user.') {
    toast.error(errorMessage)
  }
}

export function getMergedData (results) {
  return results.reduce((accum, result) => [...accum, result.data.data], [])
}

export function * joinAll (tasks) {
  try {
    const joinedResults = yield all(tasks.map(t => join(t)))
    return joinedResults
  } catch (e) {
    throw new Error(e)
  }
}

export function * resolveTasks (tasks, transformFn) {
  try {
    const joinedResults = yield joinAll(tasks)
    const mergedData = getMergedData(joinedResults)
    return typeof transformFn === 'function' ? mergedData.map(transformFn) : mergedData
  } catch (e) {
    throw new Error(e)
  }
}

export const apiCheck = async (name, value, url, errorKey) => {
  try {
    await axios.post(url, { [name]: value })
    return { okay: true }
  } catch (e) {
    // showErrorMessage(e)
    const response = e && e.response
    const status = response && response.status
    if (status === 401) {
      toast.error(i18n.t(errorKey))
    }
    return { okay: false, status }
  }
}
/**
 * Asynchronously requests to set authentication cookies.
 * If the request fails, it displays an error toast notification.
 *
 * @returns {Promise<void>} A promise that resolves when the request is completed.
 */
export const setAuthCookies = async permissionType => {
  try {
    const data = await axios.get(`/set_auth_cookies?permission_type=${permissionType}`, { withCredentials: true })
    return data.status === 200
  } catch (e) {
    showErrorMessage(e)
    return false
  }
}
/**
 * Check if email can be used for registration (not used by any other user)
 * @param {String} email
 */
export const apiCheckEmail = async email => {
  return apiCheck('email', email, '/check_email', 'toast.register.checkEmailError')
}
/**
 * Check if company name can be used for registration (not used by any other company)
 * @param {String} email
 */
export const apiCheckCompany = async companyName => {
  return apiCheck('name', companyName, '/check_company_name', 'toast.register.checkCompanyError')
}

export const createQueryString = ({ search = '', pageSize = 100, page = 1, orderBy = 'created_at', order = 'desc', additional }) => {
  const keys = ['per_page', 'page', 'order_by', 'order', 'keyword']
  const values = [pageSize, page, orderBy, order, search]
  const query = values
    .map((value, index) => value ? `${keys[index]}=${value}` : null)
    .filter(Boolean)
    .join('&')
  return `?${query}${additional ? `&${additional}` : ''}`
}
const MAXIMUM_ENTRIES = 1000000

export const createQueryStringMaximumEntries = ({ search = '', orderBy = 'created_at', order = 'desc', additional = '' }) => {
  return createQueryString({ search, pageSize: MAXIMUM_ENTRIES, page: 1, orderBy, order, additional })
}

export const getPaginationFromResponse = (total, page, pageSize, order, order_by) => {
  return {
    page: page,
    total: total,
    total_pages: total <= pageSize ? 1 : Math.ceil(total / pageSize),
    page_size: pageSize,
    order: order,
    order_by: order_by,
  }
}

export const allSettled = effects =>
  all(
    effects.map(effect =>
      call(function * settle () {
        try {
          return { error: false, result: yield effect }
        } catch (err) {
          return { error: true, result: err }
        }
      }),
    ),
  )
