export const Route = {
  DASHBOARD: 'dashboard',
  ERROR: 'error',
  REGISTER: 'register',
  REGISTER_SUCCESS: 'register_success',
  COMPANY_USER_REGISTER_SUCCESS: 'companies/register_success',
  PAYMENTS: 'payments',
  USERS: 'users',
  COMPANY: 'company',
  TERMS: 'terms',
  STATISTICS: 'statistics',
  SYSTEM_TYPES: 'system_types',
  ROVERS: 'rovers',
  LOGS: 'logs',
  NOTIFICATIONS: 'notifications',
  RECALLS: 'technical_service_bulletins',
  DA_EVENTS: 'da_events',
  DA_REQUEST_EVENTS: 'da_request_events',
  RENTS: 'rents',
  RMAS: 'rmas',
  SUBSCRIPTION: 'subscription',
  REGISTER_SYSTEM: 'register/system',
  RELEASES: 'releases',
  EXPIRED_SUBSCRIPTIONS: 'subscriptions/expired',
  SE_CRASHES: 'statistics/se_crashes',
}

export const Routes = Object.values(Route).reduce((all, value) => ({ ...all, [value]: `/${value}` }), {})
export const BasicRoutes = Object.keys(Route).reduce((all, key) => ({ ...all, [key]: `/${Route[key]}` }), {})

export function getBasicRouteUrl (route) {
  return Routes[route] || route
}
