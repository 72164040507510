import { call, put, takeLatest, fork, select, all } from 'redux-saga/effects'
import AppActions, { AppTypes } from './actions'
import axios, { axiosComponentInfo, axiosLidarmill, axiosClient, axiosResources } from 'utils/axios'
import { isConstantsLoaded } from './selectors'
import { allSettled, createQueryString, createQueryStringMaximumEntries, getErrorMessage, getPaginationFromResponse } from 'utils/api'
import { getLoggedUser } from 'modules/users/selectors'
import { isUserPLS } from 'utils/company'
import { toast } from 'react-toastify'
import { EventsWithWorkList, getRoverWorkListsURL, shouldFilterWorkListsByTag } from 'utils/roverEvents'

function * getTags () {
  yield put(AppActions.getTagsLoading())
  try {
    const { data: { data: tags } } = yield call(axios.get, '/tags')
    yield put(AppActions.getTagsSuccess(tags))
  } catch (e) {
    yield put(AppActions.getTagsFailure(getErrorMessage(e)))
  }
}

function * getConstants () {
  const constantsLoaded = yield select(state => isConstantsLoaded(state))
  if (!constantsLoaded) {
    yield put(AppActions.getConstantsLoading())
    try {
      const [
        { data: { data: constants } },
      ] = yield all([
        call(axios.get, '/constants/all'),
      ])
      yield put(AppActions.getConstantsSuccess(
        constants.countries,
        constants.industries,
        constants.time_zones,
      ))
      yield put(AppActions.getAntennas())
    } catch (e) {
      yield put(AppActions.getConstantsFailure(getErrorMessage(e)))
    }
  }
}

export function * getAntennas () {
  yield put(AppActions.getAntennasLoading())
  try {
    const { data: antennas } = yield call(axiosResources.get, '/antennas/?exclude=calibrations_antex,calibrations_antinfo,updated,created&supported_by_ie=true')
    yield put(AppActions.getAntennasSuccess(antennas))
  } catch (e) {
    yield put(AppActions.getAntennasFailure())
  }
}

function * getDAREventTypes () {
  yield put(AppActions.getDAREventTypesLoading())
  try {
    const { data: { data: darEventTypes } } = yield call(axios.get, '/data_acquisition_request_events/event_types')
    yield put(AppActions.getDAREventTypesSuccess(darEventTypes))
  } catch (e) {
    yield put(AppActions.getDAREventTypesFailure(getErrorMessage(e)))
  }
}

/**
 * Fetches recall types from the API.
 */
function * getRecallTypes () {
  yield put(AppActions.getRecallTypesLoading())
  try {
    const { data: { data: recallTypes } } = yield call(axios.get, '/recalls/types')
    yield put(AppActions.getRecallTypesSuccess(recallTypes))
  } catch (e) {
    yield put(AppActions.getRecallTypesFailure(getErrorMessage(e)))
  }
}

function * getDAEventIssueTypes () {
  yield put(AppActions.getDAEventIssueTypesLoading())
  try {
    const { data: { data: daIssueTypes } } = yield call(axios.get, '/data_acquisition_events/issue_types')
    yield put(AppActions.getDAEventIssueTypesSuccess(daIssueTypes))
  } catch (e) {
    yield put(AppActions.getDAEventIssueTypesFailure(getErrorMessage(e)))
  }
}

function * getCheckListSteps () {
  yield put(AppActions.getCheckListStepsLoading())
  try {
    const { data: { data: checkListSteps } } = yield call(axios.get, '/checklist_steps')
    yield put(AppActions.getCheckListStepsSuccess(checkListSteps))
  } catch (e) {
    yield put(AppActions.getCheckListStepsFailure(getErrorMessage(e)))
  }
}

function * getRMAStatuses () {
  yield put(AppActions.getRMAStatusesLoading())
  try {
    const { data: { data: rmaStatuses } } = yield call(axios.get, '/rmas/statuses')
    yield put(AppActions.getRMAStatusesSuccess(rmaStatuses))
  } catch (e) {
    yield put(AppActions.getRMAStatusesFailure(getErrorMessage(e)))
  }
}

function * getSystemTypeStatuses () {
  yield put(AppActions.getSystemTypeStatusesLoading())
  try {
    const { data: { data: rmaStatuses } } = yield call(axios.get, '/rovers_system_types/statuses')
    yield put(AppActions.getSystemTypeStatusesSuccess(rmaStatuses))
  } catch (e) {
    yield put(AppActions.getSystemTypeStatusesFailure(getErrorMessage(e)))
  }
}

function * getProductReleases ({ productName }) {
  yield put(AppActions.getProductReleasesLoading(productName))
  try {
    const loggedUser = yield select(state => getLoggedUser(state))
    const { data: { data: releases } } = yield call(axios.get, `/products/${productName}/releases`)
    yield put(AppActions.getProductReleasesSuccess(productName, releases, isUserPLS(loggedUser)))
  } catch (e) {
    yield put(AppActions.getProductReleasesFailure(productName, getErrorMessage(e)))
  }
}

function * getProducts () {
  yield put(AppActions.getProductsLoading())
  try {
    const { data: products } = yield call(axiosClient.get, `/products`)
    console.log(products)
    yield put(AppActions.getProductsSuccess(products))
  } catch (e) {
    yield put(AppActions.getProductsFailure(getErrorMessage(e)))
  }
}

function * getULData () {
  yield put(AppActions.getULDataLoading())
  try {
    const [
      { data: { data: sales_invoices } },
      { data: { data: sales_orders } },
      { data: { data: purchase_orders } },
      { data: { data: credit_notes } },
    ] = yield all([
      call(axios.get, '/ul/sales_invoices'),
      call(axios.get, '/ul/sales_orders'),
      call(axios.get, '/ul/purchase_orders'),
      call(axios.get, '/ul/credit_notes'),
    ])
    yield put(AppActions.getULDataSuccess(sales_invoices, sales_orders, purchase_orders, credit_notes))
  } catch (e) {
    yield put(AppActions.getULDataFailure(getErrorMessage(e)))
  }
}

export function * getAllSystemTypes () {
  yield put(AppActions.getAllSystemTypesLoading())
  try {
    const { data: { data: systemTypes } } = yield call(axios.get, '/system_types')
    yield put(AppActions.getAllSystemTypesSuccess(systemTypes))
  } catch (e) {
    yield put(AppActions.getAllSystemTypesFailure())
  }
}

export function * getAllCustomerTypes () {
  yield put(AppActions.getAllCustomerTypesLoading())
  try {
    const { data: { data: customerTypes } } = yield call(axios.get, '/companies/customer_types')
    yield put(AppActions.getAllCustomerTypesSuccess(customerTypes))
  } catch (e) {
    yield put(AppActions.getAllCustomerTypesFailure())
  }
}

function * getLogs ({ search = '', pageSize = 100, page = 1, orderBy = 'timestamp', order = 'desc' }) {
  yield put(AppActions.getLogsLoading())
  try {
    const { data: { data: logs, pagination } } = yield call(
      axios.get,
      `/db_logs/${createQueryString({ search, pageSize, page, order, orderBy })}`,
    )
    yield put(AppActions.getLogsSuccess(logs, getPaginationFromResponse(pagination.total, page, pageSize)))
  } catch (e) {
    yield put(AppActions.getLogsFailure(e.message || 'Error ocurred'))
  }
}

function * getDAEvents ({ search = '', pageSize = 100, page = 1, orderBy = 'timestamp', order = 'desc', filter = '', withLoading = true }) {
  if (withLoading) {
    yield put(AppActions.getDAEventsLoading())
  }
  try {
    const { data: { data: daEvents, pagination } } = yield call(
      axios.get,
      `/data_acquisition_events${createQueryString({ search, pageSize, page, order, orderBy, additional: filter })}`,
    )
    yield put(AppActions.getDAEventsSuccess(daEvents, { ...getPaginationFromResponse(pagination.total, page, pageSize, order, orderBy), search, filter }))
  } catch (e) {
    yield put(AppActions.getDAEventsFailure(e.message || 'Error ocurred'))
  }
}

function * getAllDAEvents () {
  yield put(AppActions.getAllDAEventsLoading())
  try {
    const { data: { data: daEvents } } = yield call(
      axios.get,
      `/data_acquisition_events${createQueryStringMaximumEntries({ orderBy: 'timestamp', order: 'desc' })}`,
    )
    yield put(AppActions.getAllDAEventsSuccess(daEvents))
  } catch (e) {
    yield put(AppActions.getAllDAEventsFailure(e.message || 'Error ocurred'))
  }
}

function * getDARequestEvents ({ search = '', pageSize = 100, page = 1, orderBy = 'status', order = 'desc', filter = '', withLoading = true }) {
  if (withLoading) {
    yield put(AppActions.getDARequestEventsLoading())
  }
  try {
    const { data: { data: daEvents, pagination } } = yield call(
      axios.get,
      `/data_acquisition_request_events${createQueryString({ search, pageSize, page, order, orderBy, additional: filter })}`,
    )
    yield put(AppActions.getDARequestEventsSuccess(daEvents, { ...getPaginationFromResponse(pagination.total, page, pageSize, order, orderBy), search, filter }))
  } catch (e) {
    yield put(AppActions.getDARequestEventsFailure(e.message || 'Error ocurred'))
  }
}

function * getAllDARequestEvents () {
  yield put(AppActions.getAllDARequestEventsLoading())
  try {
    const { data: { data: darEvents } } = yield call(
      axios.get,
      `/data_acquisition_request_events${createQueryStringMaximumEntries({ orderBy: 'status', order: 'desc' })}`,
    )
    yield put(AppActions.getAllDARequestEventsSuccess(darEvents))
  } catch (e) {
    yield put(AppActions.getAllDARequestEventsFailure(e.message || 'Error ocurred'))
  }
}

function * getRentEvents ({ search = '', pageSize = 100, page = 1, orderBy = 'timestamp', order = 'desc', filter = '', withLoading = true }) {
  if (withLoading) {
    yield put(AppActions.getRentEventsLoading())
  }
  try {
    const { data: { data: rentEvents, pagination } } = yield call(
      axios.get,
      `/rent_events${createQueryString({ search, pageSize, page, order, orderBy, additional: filter })}`,
    )
    yield put(AppActions.getRentEventsSuccess(rentEvents, { ...getPaginationFromResponse(pagination.total, page, pageSize, order, orderBy), search, filter }))
  } catch (e) {
    yield put(AppActions.getRentEventsFailure(e.message || 'Error ocurred'))
  }
}

function * getAllRentEvents () {
  yield put(AppActions.getAllRentEventsLoading())
  try {
    const { data: { data: rentEvents } } = yield call(
      axios.get,
      `/rent_events${createQueryStringMaximumEntries({ orderBy: 'timestamp', order: 'desc' })}`,
    )
    yield put(AppActions.getAllRentEventsSuccess(rentEvents))
  } catch (e) {
    yield put(AppActions.getAllRentEventsFailure(e.message || 'Error ocurred'))
  }
}

function * getNotifications ({ search = '', pageSize = 100, page = 1, orderBy = 'created', order = 'desc', filter = '' }) {
  yield put(AppActions.getNotificationsLoading())
  try {
    const { data: { data: notifications, pagination } } = yield call(
      axios.get,
      `/notifications${createQueryString({ search, pageSize, page, order, orderBy, additional: filter })}`,
    )
    yield put(AppActions.getNotificationsSuccess(notifications, getPaginationFromResponse(pagination.total, page, pageSize)))
  } catch (e) {
    yield put(AppActions.getNotificationsFailure(e.message || 'Error ocurred'))
  }
}

function * getPropertyTypes ({ withLoading = true }) {
  if (withLoading) yield put(AppActions.getPropertyTypesLoading())
  try {
    const { data } = yield call(axiosComponentInfo.get, `/property_types`)
    yield put(AppActions.getPropertyTypesSuccess(data))
  } catch (e) {
    yield put(AppActions.getPropertyTypesFailure(e.message || e))
  }
}

function * getComponentTypes ({ withLoading = true }) {
  if (withLoading) yield put(AppActions.getComponentTypesLoading())
  try {
    const { data } = yield call(axiosComponentInfo.get, `/component_types`)
    yield put(AppActions.getComponentTypesSuccess(data))
  } catch (e) {
    yield put(AppActions.getComponentTypesFailure(e.message || e))
  }
}

function * getAllProjects () {
  try {
    const { data: { data: projects } } = yield call(axiosLidarmill.get, `/projects?exclude=trajectories,gcps,project_thumbnails`)
    yield put(AppActions.getAllProjectsSuccess(projects))
  } catch (e) {
  }
}

/**
 * Retrieves work lists based on event types and optional tags.
 *
 * @param {string} tag - Optional tag to filter the work lists.
 */
function * getWorkLists ({ tag }) {
  yield put(AppActions.getWorkListsLoading())
  try {
    const tasks = []
    const types = []
    for (const eventType of EventsWithWorkList) {
      let url = getRoverWorkListsURL(eventType)
      url += shouldFilterWorkListsByTag(eventType) ? `?tag=${tag}` : ''
      types.push(eventType)
      tasks.push(call(axios.get, url))
    }
    const joinedResults = yield allSettled(tasks)
    const workListsByRoverEvent = joinedResults.reduce((allResults, result, index) => {
      const eventType = types[index]
      if (result.error) {
        toast.error('Failed to retrieve ' + eventType + ' work lists')
        return allResults
      }
      const { data: { data: workLists } } = result.result
      return {
        ...allResults,
        [eventType]: workLists,
      }
    }, {})
    yield put(AppActions.getWorkListsSuccess(workListsByRoverEvent))
  } catch (e) {
    yield put(AppActions.getWorkListsFailure(getErrorMessage(e)))
  }
}

function * getConstantsWatcher () {
  yield takeLatest(AppTypes.GET_CONSTANTS, getConstants)
}
function * getTagsWatcher () {
  yield takeLatest(AppTypes.GET_TAGS, getTags)
}
function * getAllSystemTypesWatcher () {
  yield takeLatest(AppTypes.GET_ALL_SYSTEM_TYPES, getAllSystemTypes)
}
function * getAllCustomerTypesWatcher () {
  yield takeLatest(AppTypes.GET_ALL_CUSTOMER_TYPES, getAllCustomerTypes)
}
function * getLogsWatcher () {
  yield takeLatest(AppTypes.GET_LOGS, getLogs)
}
function * getDAEventsWatcher () {
  yield takeLatest(AppTypes.GET_DA_EVENTS, getDAEvents)
}
function * getAllDAEventsWatcher () {
  yield takeLatest(AppTypes.GET_ALL_DA_EVENTS, getAllDAEvents)
}
function * getDARequestEventsWatcher () {
  yield takeLatest(AppTypes.GET_DA_REQUEST_EVENTS, getDARequestEvents)
}
function * getAllDARequestEventsWatcher () {
  yield takeLatest(AppTypes.GET_ALL_DA_REQUEST_EVENTS, getAllDARequestEvents)
}
function * getRentEventsWatcher () {
  yield takeLatest(AppTypes.GET_RENT_EVENTS, getRentEvents)
}
function * getAllRentEventsWatcher () {
  yield takeLatest(AppTypes.GET_ALL_RENT_EVENTS, getAllRentEvents)
}
function * getNotificationsWatcher () {
  yield takeLatest(AppTypes.GET_NOTIFICATIONS, getNotifications)
}
function * getPropertyTypesWatcher () {
  yield takeLatest(AppTypes.GET_PROPERTY_TYPES, getPropertyTypes)
}
function * getComponentTypesWatcher () {
  yield takeLatest(AppTypes.GET_COMPONENT_TYPES, getComponentTypes)
}
function * getULDataWatcher () {
  yield takeLatest(AppTypes.GET_UL_DATA, getULData)
}
function * getAllProjectsWatcher () {
  yield takeLatest(AppTypes.GET_ALL_PROJECTS, getAllProjects)
}
function * getProductReleasesWatcher () {
  yield takeLatest(AppTypes.GET_PRODUCT_RELEASES, getProductReleases)
}
function * getDAREventTypesWatcher () {
  yield takeLatest(AppTypes.GET_DAR_EVENT_TYPES, getDAREventTypes)
}
function * getRecallTypesWatcher () {
  yield takeLatest(AppTypes.GET_RECALL_TYPES, getRecallTypes)
}
function * getDAEventIssueTypesWatcher () {
  yield takeLatest(AppTypes.GET_DA_EVENT_ISSUE_TYPES, getDAEventIssueTypes)
}
function * getCheckListStepsWatcher () {
  yield takeLatest(AppTypes.GET_CHECK_LIST_STEPS, getCheckListSteps)
}
function * getRMAStatusesWatcher () {
  yield takeLatest(AppTypes.GET_RMA_STATUSES, getRMAStatuses)
}
function * getSystemTypeStatusesWatcher () {
  yield takeLatest(AppTypes.GET_SYSTEM_TYPE_STATUSES, getSystemTypeStatuses)
}
function * getProductsWatcher () {
  yield takeLatest(AppTypes.GET_PRODUCTS, getProducts)
}
function * getWorkListsWatcher () {
  yield takeLatest(AppTypes.GET_WORK_LISTS, getWorkLists)
}
function * getAntennasWatcher () {
  yield takeLatest(AppTypes.GET_ANTENNAS, getAntennas)
}

export default function * root () {
  yield fork(getConstantsWatcher)
  yield fork(getTagsWatcher)
  yield fork(getAllSystemTypesWatcher)
  yield fork(getAllCustomerTypesWatcher)
  yield fork(getLogsWatcher)
  yield fork(getDAEventsWatcher)
  yield fork(getDARequestEventsWatcher)
  yield fork(getRentEventsWatcher)
  yield fork(getNotificationsWatcher)
  yield fork(getPropertyTypesWatcher)
  yield fork(getComponentTypesWatcher)
  yield fork(getULDataWatcher)
  yield fork(getAllProjectsWatcher)
  yield fork(getProductReleasesWatcher)
  yield fork(getDAREventTypesWatcher)
  yield fork(getDAEventIssueTypesWatcher)
  yield fork(getCheckListStepsWatcher)
  yield fork(getRMAStatusesWatcher)
  yield fork(getSystemTypeStatusesWatcher)
  yield fork(getProductsWatcher)
  yield fork(getWorkListsWatcher)
  yield fork(getAntennasWatcher)
  yield fork(getAllDAEventsWatcher)
  yield fork(getAllDARequestEventsWatcher)
  yield fork(getAllRentEventsWatcher)
  yield fork(getRecallTypesWatcher)
}
