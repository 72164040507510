import { Map } from 'immutable'
import { getDefaultPagination } from '../utils'

export const INITIAL_STATE = Map({
  recalls: [],
  pagination: getDefaultPagination({ page_size: 50, order: 'desc', order_by: 'created' }),
  createRecallIsLoading: false,
  createRecallEventIsLoading: false,
  getRecallsIsLoading: false,
  editRecallIsLoading: {},

  recallEvents: {},
  getRecallEventsIsLoading: {},
  editRecallEventIsLoading: {},
  deleteRecallEventIsLoading: {},

  selectedRecall: null,
  selectRecallIsLoading: false,

  publishRecallIsLoading: false,

  addAttachmentsIsLoading: false,
})
