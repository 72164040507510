import config from 'config'
import moment from 'utils/moment'
import moment_ from 'moment'

/**
 * Returns the prettified version (..h..m..s..ms) of the time based on milliseconds
 * @param {number} milliseconds
 * @param {boolean} withNull show zero hours, minutes and etc
 */
export function prettifyTime (milliseconds, withNull = true) {
  const duration = moment.duration(milliseconds, 'milliseconds')
  const hours = duration.days() > 0 ? duration.hours() + (duration.days() * 24) : duration.hours()
  const durationInSeconds = duration.seconds()
  const durationInMinutes = duration.minutes()
  const displayMilliseconds = durationInSeconds === 0 && durationInMinutes === 0 && hours === 0
    ? duration.milliseconds()
    : null
  return `${!withNull ? hours > 0 ? hours + 'h ' : '' : `${hours}h`}${!withNull ? durationInMinutes > 0 ? durationInMinutes + 'm ' : '' : `${durationInMinutes}m`}${!withNull ? durationInSeconds > 0 ? durationInSeconds + 's' : '' : `${durationInSeconds}s`}${displayMilliseconds ? `${displayMilliseconds}ms` : ''}`
}

export const getUTCDate = date => {
  if (date) {
    return moment_.utc(date).utcOffset('+00:00')
  }
  return moment_.utc().utcOffset('+00:00')
}

/**
 * Formats a given date according to the specified format.
 *
 * @param {moment.Moment} date - The date to be formatted. This should be a Moment.js date object.
 * @param {string} [format=config.DATETIME_FORMAT] - The format string to format the date. Defaults to the standard datetime format specified in the config.
 * @returns {string} The formatted date string.
 */
export function formatDate (date, format = config.DATETIME_FORMAT) {
  return date.format(format)
}
/**
 * Retrieves the time zone offset of the user's machine.
 * @returns {string} Time zone string representing the user's current time zone offset (ex. -2, 0, 5).
 */
export function getUserDeviceTimeZoneOffset () {
  return new Date().getTimezoneOffset() / -60
}
/**
 * Transforms a given UTC date or user current date (if it's not defined) to the specified timezone or local user TZ from browser if it’s not defined using Moment.js.
 *
 * @param {moment.Moment} date - The date to be converted, assumed to be in UTC.
 * @param {string} timezone - The timezone identifier to which the date should be converted.
 * @returns {moment.Moment} The date string converted to the specified timezone.
 */
export function getDateInTimezone (date, timezone) {
  // Check if the timezone is invalid and use the browser's local timezone
  if (!timezone || typeof timezone !== 'string' || !moment.tz.zone(timezone)) {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
    return getUTCDate(date).tz(timeZone)
  }
  return getUTCDate(date).tz(timezone)
}
/**
 * Formats a given date into a specified timezone and format.
 *
 * @param {moment.Moment} date - The date to be formatted, which should be a Moment.js date object.
 * @param {string} timezone - The timezone identifier (e.g., 'America/New_York') to which the date should be converted.
 * @param {string} format - The format string to format the date (e.g., 'YYYY-MM-DD HH:mm:ss').
 * @returns {string} The formatted date string in the specified timezone.
 */
export function formatDateInTimezone (date, timezone, format) {
  return date ? formatDate(getDateInTimezone(date, timezone), format) : null
}
