import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  getAllRovers: null,
  getAllRoversLoading: null,
  getAllRoversSuccess: ['rovers'],
  getAllRoversFailure: ['errorMessage'],

  getRovers: ['search', 'pageSize', 'page', 'orderBy', 'order', 'filter'],
  getRoversLoading: null,
  getRoversSuccess: ['rovers', 'pagination'],
  getRoversFailure: ['errorMessage'],

  getRoverCalibrations: ['serial'],
  getRoverCalibrationsLoading: ['serial'],
  getRoverCalibrationsSuccess: ['serial', 'calibrations'],
  getRoverCalibrationsFailure: ['serial', 'errorMessage'],

  getRoverCustomSettings: ['serial'],
  getRoverCustomSettingsLoading: ['serial'],
  getRoverCustomSettingsSuccess: ['serial', 'settings'],
  getRoverCustomSettingsFailure: ['serial', 'errorMessage'],

  addRoverCustomSetting: ['serial', 'setting', 'comment', 'onSuccess'],
  addRoverCustomSettingLoading: ['serial'],
  addRoverCustomSettingSuccess: ['serial', 'setting'],
  addRoverCustomSettingFailure: ['serial', 'errorMessage'],

  setExpandedCustomSetting: ['id'],

  /*
  deleteRoverCustomSetting: ['serial', 'id'],
  deleteRoverCustomSettingLoading: ['serial'],
  deleteRoverCustomSettingSuccess: ['serial', 'id'],
  deleteRoverCustomSettingFailure: ['serial', 'errorMessage'],
  */

  getCustomerRovers: ['customerId'],
  getCustomerRoversLoading: ['customerId'],
  getCustomerRoversSuccess: ['customerId', 'rovers'],
  getCustomerRoversFailure: ['errorMessage'],

  addRover: ['serial', 'onSuccess'], // 'systemTypeId', 'macAddress',
  addRoverLoading: [],
  addRoverSuccess: ['rover'],
  addRoverFailure: ['errorMessage'],

  editRover: ['serial', 'formData', 'onSuccess'],
  editRoverLoading: ['serial'],
  editRoverSuccess: ['serial', 'rover'],
  editRoverTagsSuccess: ['serial', 'tags'],
  editRoverFailure: ['errorMessage'],

  editRoverCalibration: ['serial', 'slug', 'formData'],
  editRoverCalibrationLoading: ['serial', 'slug'],
  editRoverCalibrationSuccess: ['serial', 'slug', 'formData'],
  editRoverCalibrationFailure: ['serial', 'slug', 'errorMessage'],

  deleteRoverCalibration: ['serial', 'slug'],
  deleteRoverCalibrationLoading: ['serial', 'slug'],
  deleteRoverCalibrationSuccess: ['serial', 'slug'],
  deleteRoverCalibrationFailure: ['serial', 'slug', 'errorMessage'],

  enableDownloadRoverSSH: ['serial'],
  enableDownloadRoverSSHLoading: ['serial'],
  enableDownloadRoverSSHSuccess: ['serial', 'rover'],
  enableDownloadRoverSSHFailure: ['serial', 'errorMessage'],

  downloadPublicSSH: ['serial'],
  downloadPrivateSSH: ['serial'],
  downloadRoverSettings: ['serial'],
  downloadRMAReport: ['serial', 'onSuccess'],
  downloadCalibrationCertificate: ['event', 'attachment'],

  getRoverEvents: ['serial', 'events', 'search'],
  getRoverEventsLoading: ['serial'],
  getRoverEventsSuccess: ['serial', 'events'],
  getRoverEventsFailure: ['serial'],

  getRoverProjects: ['serial', 'rover'],
  getRoverProjectsLoading: ['serial'],
  getRoverProjectsSuccess: ['serial', 'projects', 'companyProjects'],
  getRoverProjectsFailure: ['serial'],

  addRoverEvent: ['companyId', 'serial', 'eventType', 'data', 'successCallback'],
  addRoverEventLoading: ['serial'],
  addRoverEventSuccess: ['serial', 'eventType', 'event'],
  addRoverEventFailure: ['serial'],

  addChildEvent: ['serial', 'parentEventId', 'parentEventType', 'eventType', 'data', 'successCallback'],
  addChildEventLoading: ['serial'],
  addChildEventSuccess: ['serial', 'parentEventId', 'parentEventType', 'eventType', 'event', 'parentEvent'],
  addChildEventFailure: ['serial'],

  getSystemReports: ['serial', 'pageSize', 'page', 'withLoading'],
  getSystemReportsLoading: ['serial'],
  getSystemReportsSuccess: ['serial', 'reports', 'pagination'],
  getSystemReportsFailure: ['serial'],

  getReportComponents: ['reportId', 'withLoading'],
  getReportComponentsLoading: ['reportId'],
  getReportComponentsSuccess: ['components', 'reportId'],
  getReportComponentsFailure: ['errorMessage', 'reportId'],

  updateRoverSuccess: ['serial', 'data'],
  selectEvent: ['event'],
  deselectEvent: [],

  approveEvent: ['serial', 'eventId', 'eventType', 'data', 'onSuccess'],
  approveRegisterEvent: ['serial', 'eventId', 'data', 'onSuccess'],
  approveEventLoading: [],
  approveEventSuccess: ['serial', 'eventId', 'event', 'eventType'],
  approveEventFailure: [],

  disapproveEvent: ['serial', 'eventId', 'eventType', 'data', 'onSuccess'],
  disapproveEventLoading: [],
  disapproveEventSuccess: ['serial', 'eventId', 'event', 'eventType'],
  disapproveEventFailure: [],

  rejectEvent: ['serial', 'eventId', 'eventType', 'data', 'onSuccess'],
  rejectEventLoading: [],
  rejectEventSuccess: ['serial', 'eventId', 'event', 'eventType'],
  rejectEventFailure: [],

  addEventAttachments: ['serial', 'eventId', 'eventType', 'attachments', 'onSuccess'],
  addEventAttachmentsLoading: [],
  addEventAttachmentsSuccess: ['serial', 'eventId', 'eventType', 'attachments'],
  addEventAttachmentsFailure: [],

  closeEvent: ['serial', 'eventId', 'eventType', 'closed_at', 'onSuccess'],
  closeEventLoading: [],
  closeEventSuccess: ['serial', 'eventId', 'event', 'eventType'],
  closeEventFailure: [],

  editEvent: ['event', 'serial', 'eventId', 'eventType', 'data', 'onSuccess'],
  editEventLoading: [],
  editEventSuccess: ['serial', 'eventId', 'eventType', 'event'],
  editEventFailure: [],

  deleteEvent: ['serial', 'eventId', 'eventType', 'onSuccess'],
  deleteEventLoading: [],
  deleteEventSuccess: ['serial', 'eventId'],
  deleteEventFailure: [],

  deleteEventAttachments: ['serial', 'eventId', 'eventType', 'attachmentIds', 'onSuccess'],
  deleteEventAttachmentsLoading: [],
  deleteEventAttachmentsSuccess: ['serial', 'eventId', 'eventType', 'attachmentIds'],
  deleteEventAttachmentsFailure: [],

  getEventDBLogs: ['eventId', 'eventType', 'pagination', 'onSuccess'],
  getEventDBLogsLoading: ['eventId'],
  getEventDBLogsSuccess: ['eventId', 'eventType', 'logs', 'pagination'],
  getEventDBLogsFailure: ['eventId'],

  getCheckListStepDBLogs: ['eventType', 'stepId', 'pagination', 'onSuccess'],
  getCheckListStepDBLogsLoading: ['eventType', 'stepId'],
  getCheckListStepDBLogsSuccess: ['eventType', 'stepId', 'logs', 'pagination'],
  getCheckListStepDBLogsFailure: ['eventType', 'stepId'],

  setShowRMADialog: ['show', 'rmaId'],
  setRegisterSystemDialog: ['show'],

  registerSystem: ['companyId', 'data', 'onSuccess'],
  registerSystemLoading: [],
  registerSystemSuccess: ['companyId', 'event'],
  registerSystemFailure: [],

  addEventStatus: ['serial', 'eventId', 'eventType', 'data', 'notifyUsers', 'onSuccess'],
  addEventStatusLoading: [],
  addEventStatusSuccess: ['serial', 'eventId', 'eventType', 'event'],
  addEventStatusFailure: [],

  getRoversCSV: [],
  getRoversCSVLoading: [],
  getRoversCSVSuccess: ['csv'],
  getRoversCSVFailure: [],

  getRoverUpdates: ['serial'],
  getRoverUpdatesLoading: ['serial'],
  getRoverUpdatesSuccess: ['serial', 'updates'],
  getRoverUpdatesFailure: ['serial'],

  getRoverVersion: ['serial'],
  getRoverVersionLoading: ['serial'],
  getRoverVersionSuccess: ['serial', 'firmware_version'],
  getRoverVersionFailure: ['serial'],

  getBuildEvent: ['serial', 'eventId'],
  getBuildEventLoading: null,
  getBuildEventSuccess: ['serial', 'eventId', 'event'],
  getBuildEventFailure: null,

  getDarEventCreatibility: ['serial', 'eventType'],
  getDarEventCreatibilityLoading: ['serial'],
  getDarEventCreatibilitySuccess: ['serial', 'eventType', 'messages'],
  getDarEventCreatibilityFailure: ['serial'],
})

export const RoverTypes = Types
export default Creators
