import { call, put, takeLatest, fork, takeEvery } from 'redux-saga/effects'
import { omit } from 'ramda'
import { createQueryString, getPaginationFromResponse, joinAll, showErrorMessage } from '../../utils/api'
import axios from 'utils/axios'
import { getErrorMessage } from 'utils/api'
// Local deps
import RecallsActions, { RecallsTypes } from './actions'
import { toast } from 'react-toastify'
import { RoverEventType } from 'types/roverEvents'
import { addAttachments } from 'modules/utils'

// Sagas
function * getRecalls ({ search = '', pageSize = 50, page = 1, orderBy = 'updated_at', order = 'desc' }) {
  yield put(RecallsActions.getRecallsLoading())
  try {
    //, pagination
    const { data: { data: recalls, pagination } } = yield call(
      axios.get,
      `/recalls${createQueryString({ search, pageSize, page, order, orderBy })}`,
    )
    yield put(RecallsActions.getRecallsSuccess(
      recalls,
      getPaginationFromResponse(pagination.total, page, pageSize, order, orderBy),
    ))
  } catch (e) {
    yield put(RecallsActions.getRecallsFailure(getErrorMessage(e)))
  }
}
function * getRecallEvents ({ recallId }) {
  yield put(RecallsActions.getRecallEventsLoading(recallId))
  try {
    const { data: { data: recallEvents } } = yield call(
      axios.get,
      `/recalls/${recallId}/recall_events`,
    )
    yield put(RecallsActions.getRecallEventsSuccess(recallId, recallEvents))
  } catch (e) {
    yield put(RecallsActions.getRecallEventsFailure(recallId, getErrorMessage(e)))
  }
}
function * createRecall ({ data, onSuccess }) {
  yield put(RecallsActions.createRecallLoading())
  try {
    const newData = {
      ...data,
      rover_serials: data.rover_serials.map(rs => ({
        serial: rs.serial,
        notification_recipients: (rs.notification_recipients || []),
      })),
    }
    const { data: { data: recall } } = yield call(axios.post, `/recalls`, newData)
    const attachments = data.attachments
    if (attachments) {
      const eventAttachments = yield call(addAttachments, recall.id, RoverEventType.RECALL, attachments)
      yield put(RecallsActions.createRecallSuccess({ ...recall, attachments: eventAttachments }, recall.recall_events))
    } else {
      yield put(RecallsActions.createRecallSuccess(recall, recall.recall_events))
    }
    if (onSuccess) {
      onSuccess()
    }
    toast.success('Technical Service Bulletins has been successfully created!')
  } catch (e) {
    showErrorMessage(e)
    yield put(RecallsActions.createRecallFailure(getErrorMessage(e)))
  }
}

function * deleteRecallAttachments ({ eventId, eventType, attachmentIds, onSuccess }) {
  yield put(RecallsActions.deleteRecallAttachmentsLoading(eventId))
  try {
    const tasks = []
    for (const attachmentId of attachmentIds) {
      tasks.push(yield fork(axios.delete, `/attachments/${attachmentId}`))
    }
    yield joinAll(tasks)
    yield put(RecallsActions.deleteRecallAttachmentsSuccess(eventId, eventType, attachmentIds))
    const manyDocuments = attachmentIds.length > 1
    toast.success(`Document${manyDocuments ? 's' : ''} ${manyDocuments ? 'have' : 'has'} been successfully deleted!`)
    if (onSuccess) {
      onSuccess()
    }
  } catch (e) {
    showErrorMessage(e)
    yield put(RecallsActions.deleteRecallAttachmentsFailure(eventId, e.message || 'Error ocurred'))
  }
}

function * addRecallAttachments ({ eventId, eventType, attachments, onSuccess }) {
  yield put(RecallsActions.addRecallAttachmentsLoading(eventId))
  try {
    const eventAttachments = yield call(addAttachments, eventId, eventType, attachments)
    yield put(RecallsActions.addRecallAttachmentsSuccess(eventId, eventType, eventAttachments))
    const manyDocuments = attachments.length > 1
    toast.success(`Document${manyDocuments ? 's' : ''} ${manyDocuments ? 'have' : 'has'} benn successfully added!`)
    if (onSuccess) {
      onSuccess()
    }
  } catch (e) {
    showErrorMessage(e)
    yield put(RecallsActions.addRecallAttachmentsFailure(eventId, e.message || 'Error ocurred'))
  }
}

function * editRecall ({ recallId, data, onSuccess }) {
  yield put(RecallsActions.editRecallLoading(recallId))
  try {
    const attachments = data.attachments
    const dataToSend = {
      ...omit(['attachments'], data),
    }
    const { data: { data: recall } } = yield call(axios.patch, `/recalls/${recallId}`, dataToSend)
    if (attachments && attachments.length > 0) {
      const eventAttachments = yield call(
        addAttachments,
        recallId,
        RoverEventType.RECALL,
        attachments,
      )
      yield put(RecallsActions.editRecallSuccess(recallId, ({ ...recall, attachments: [...eventAttachments, ...recall.attachments] })))
    } else {
      yield put(RecallsActions.editRecallSuccess(recallId, recall))
    }
    if (onSuccess) {
      onSuccess()
    }
    // yield put(RecallsActions.editRecallSuccess(recallId, recall))
    toast.success('Technical Service Bulletins has been successfully updated!')
  } catch (e) {
    showErrorMessage(e)
    yield put(RecallsActions.editRecallFailure(recallId, getErrorMessage(e)))
  }
}

function * editRecallEvent ({ recallId, recallEventId, data, onSuccess }) {
  yield put(RecallsActions.editRecallEventLoading(recallEventId))
  try {
    const attachments = data.attachments
    const dataToSend = {
      ...omit(['attachments'], data),
    }
    const { data: { data: recallEvent } } = yield call(axios.patch, `/recall_events/${recallEventId}`, dataToSend)
    if (attachments && attachments.length > 0) {
      const eventAttachments = yield call(
        addAttachments,
        recallEventId,
        RoverEventType.RECALL_EVENT,
        attachments,
      )
      yield put(RecallsActions.editRecallEventSuccess(recallId, recallEventId, ({ ...recallEvent, attachments: [...eventAttachments, ...recallEvent.attachments] })))
    } else {
      yield put(RecallsActions.editRecallEventSuccess(recallId, recallEventId, recallEvent))
    }
    if (onSuccess) {
      onSuccess()
    }
    toast.success('Technical Service Bulletins event has been successfully updated!')
  } catch (e) {
    showErrorMessage(e)
    yield put(RecallsActions.editRecallEventFailure(recallEventId, getErrorMessage(e)))
  }
}

function * createRecallEvent ({ recallId, data, onSuccess }) {
  yield put(RecallsActions.createRecallEventLoading())
  try {
    const { data: { data: recallEvent } } = yield call(axios.post, `/recalls/${recallId}/recall_events`, data)
    if (onSuccess) {
      onSuccess()
    }
    toast.success('Technical Service Bulletins event has been successfully created!')
    yield put(RecallsActions.createRecallEventSuccess(recallId, recallEvent))
  } catch (e) {
    showErrorMessage(e)
    yield put(RecallsActions.createRecallEventFailure(getErrorMessage(e)))
  }
}

/**
 * Publishes a recall and manages the success or failure flow.
 *
 * @param {string} recallId - The ID of the recall to be published.
 * @param {Object|null} [params.data=null] - The optional data to be sent with the publish request.
 * @param {Function} [params.onSuccess] - Callback function to be executed on successful publish.
 */
function * publishRecall ({ recallId, data = null, onSuccess }) {
  yield put(RecallsActions.publishRecallLoading())
  try {
    const { data: { data: recall } } = yield call(axios.post, `/recalls/${recallId}/publish`, data)
    if (onSuccess) {
      onSuccess()
    }
    toast.success('Technical Service Bulletins has been successfully published!')
    yield put(RecallsActions.publishRecallSuccess(recall))
  } catch (e) {
    showErrorMessage(e)
    yield put(RecallsActions.publishRecallFailure(getErrorMessage(e)))
  }
}

function * deleteRecallEvent ({ recallId, recallEventId, onSuccess }) {
  yield put(RecallsActions.deleteRecallEventLoading())
  try {
    yield call(axios.delete, `/recall_events/${recallEventId}`)
    if (onSuccess) {
      onSuccess()
    }
    toast.success('Technical Service Bulletins event has been successfully deleted!')
    yield put(RecallsActions.deleteRecallEventSuccess(recallId, recallEventId))
  } catch (e) {
    showErrorMessage(e)
    yield put(RecallsActions.deleteRecallEventFailure(getErrorMessage(e)))
  }
}

function * selectRecall ({ recallId }) {
  yield put(RecallsActions.selectRecallLoading())
  try {
    const { data: { data: recall } } = yield call(axios.get, `/recalls/${recallId}`)
    yield put(RecallsActions.selectRecallSuccess(recall))
  } catch (e) {
    yield put(RecallsActions.selectRecallFailure(e.message || 'Error ocurred'))
  }
}

// Watchers
function * getRecallsWatcher () {
  yield takeEvery(RecallsTypes.GET_RECALLS, getRecalls)
}

function * getRecallEventsWatcher () {
  yield takeEvery(RecallsTypes.GET_RECALL_EVENTS, getRecallEvents)
}

function * createRecallWatcher () {
  yield takeEvery(RecallsTypes.CREATE_RECALL, createRecall)
}

function * editRecallWatcher () {
  yield takeLatest(RecallsTypes.EDIT_RECALL, editRecall)
}

function * editRecallEventWatcher () {
  yield takeLatest(RecallsTypes.EDIT_RECALL_EVENT, editRecallEvent)
}

function * deleteRecallEventWatcher () {
  yield takeLatest(RecallsTypes.DELETE_RECALL_EVENT, deleteRecallEvent)
}

function * createRecallEventWatcher () {
  yield takeLatest(RecallsTypes.CREATE_RECALL_EVENT, createRecallEvent)
}
function * publishRecallWatcher () {
  yield takeLatest(RecallsTypes.PUBLISH_RECALL, publishRecall)
}

function * selectRecallWatcher () {
  yield takeLatest(RecallsTypes.SELECT_RECALL, selectRecall)
}

function * addRecallAttachmentsWatcher () {
  yield takeLatest(RecallsTypes.ADD_RECALL_ATTACHMENTS, addRecallAttachments)
}

function * deleteRecallAttachmentsWatcher () {
  yield takeLatest(RecallsTypes.DELETE_RECALL_ATTACHMENTS, deleteRecallAttachments)
}

export default function * root () {
  yield fork(getRecallsWatcher)
  yield fork(getRecallEventsWatcher)
  yield fork(createRecallWatcher)
  yield fork(editRecallWatcher)
  yield fork(editRecallEventWatcher)
  yield fork(deleteRecallEventWatcher)
  yield fork(createRecallEventWatcher)
  yield fork(selectRecallWatcher)
  yield fork(addRecallAttachmentsWatcher)
  yield fork(deleteRecallAttachmentsWatcher)
  yield fork(publishRecallWatcher)
}
