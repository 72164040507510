import { put, takeLatest, fork, call, all } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { omit, path } from 'ramda'
// import axios from 'axios'
// Project deps
import { downloadFile, makeRequest } from 'utils/request'
import axios, { axiosComponentInfo, axiosLidarmill } from 'utils/axios'
import { allSettled, createQueryString, getPaginationFromResponse, joinAll, showErrorMessage } from 'utils/api'
import { RoverAction } from 'types/rovers'
import config, { isProductionEnvironment } from 'config'
import CompaniesActions from 'modules/companies/actions'
// Local deps
import RoverActions, { RoverTypes } from './actions'
import {
  getRoverCalibrationsWatcher,
  editRoverCalibrationWatcher,
  deleteRoverCalibrationWatcher,
} from './calibrationsSagas'
import {
  getRoverCustomSettingsWatcher,
  addRoverCustomSettingWatcher,
} from './customSettingsSagas'
import { getAddRoverEventURL, getRoverEventWorkListsURL, getRoverEventStepStatusesURL, getRoverEventsUrls, getStatusEventUrl, getChildEventsUrls, getEditRoverEventUrl, getEventDBLogsUrl, getCheckListStepDBLogsUrl, getDeleteRoverEventUrl, getRoverEventUrl } from 'utils/roverEvents'
import { convertRawRoverEvent, RoverEventType } from 'types/roverEvents'
import { addAttachments } from 'modules/utils'
import { keepProps } from 'utils/dict'
import { isReconRover } from 'utils/rovers'
// import systemJson from './system.json'
// import reportsJson from './reports.json'
// import componentsJson from './components.json'
import { transformRecursiveWorkLists } from 'utils/workList'
import { findById } from 'utils/list'
import { convertRawAttachment } from 'types/attachments'

function * getRovers ({ search = '', pageSize = 100, page = 1, orderBy = 'created_at', order = 'desc', filter = '' }) {
  yield put(RoverActions.getRoversLoading())
  try {
    const { data: { data: rovers, pagination } } = yield call(
      axios.get,
      `/rovers${createQueryString({ search, pageSize, page, orderBy, order, additional: filter })}`,
    )
    yield put(RoverActions.getRoversSuccess(rovers, getPaginationFromResponse(pagination.total, page, pageSize)))
  } catch (e) {
    yield put(RoverActions.getRoversFailure(e.message || 'Error ocurred'))
  }
}

function * getAllRovers ({ search = '', pageSize = 100000, page = 1, orderBy = 'created_at', order = 'desc' }) {
  yield put(RoverActions.getAllRoversLoading())
  try {
    const { data: { data: rovers } } = yield call(
      axios.get,
      `/rovers`,
    )
    yield put(RoverActions.getAllRoversSuccess(rovers))
  } catch (e) {
    yield put(RoverActions.getAllRoversFailure(e.message || 'Error ocurred'))
  }
}

function * addRover ({ serial, onSuccess }) {
  /* systemTypeId, macAddress, */
  yield put(RoverActions.addRoverLoading())
  try {
    const { data: { data: rover } } = yield call(makeRequest,
      `/rovers`,
      'POST',
      RoverAction.ADD_ROVER,
      {
        serial,
        // system_type: systemTypeId ? { id: systemTypeId } : null,
        // mac_address: macAddress,
      },
    )
    if (onSuccess) {
      onSuccess()
    }
    yield put(RoverActions.addRoverSuccess(rover))
  } catch (e) {
    yield put(RoverActions.addRoverFailure(e.message || 'Error ocurred'))
  }
}

function * editRover ({ serial, formData, onSuccess }) {
  yield put(RoverActions.editRoverLoading(serial))
  try {
    const formDataWithouTags = omit(['rover_tags'], formData)
    const roverTags = formData.rover_tags
    const transformedRoverTags = roverTags && roverTags.map(tag => ({ tag_name: tag.tag.name }))
    if (Object.keys(formDataWithouTags).length > 0) {
      const { data: { data: rover } } = yield call(makeRequest, `/rovers/${serial}`, 'PATCH', RoverAction.EDIT_ROVER, formDataWithouTags)
      yield put(RoverActions.editRoverSuccess(serial, rover))
    } else {
      yield put(RoverActions.editRoverSuccess(serial, undefined))
    }
    if (roverTags) {
      yield call(makeRequest, `/rovers/${serial}/tags`, 'POST', RoverAction.EDIT_ROVER, { 'rover_tags': transformedRoverTags }, false)
      yield put(RoverActions.editRoverTagsSuccess(serial, transformedRoverTags))
    }
    if (typeof onSuccess === 'function') {
      onSuccess()
    }
  } catch (e) {
    showErrorMessage(e)
    yield put(RoverActions.editRoverFailure(serial, e.message || 'Error ocurred'))
  }
}

function * enableDownloadRoverSSH ({ serial }) {
  yield put(RoverActions.enableDownloadRoverSSHLoading(serial))
  try {
    const { data: { data: rover } } = yield call(makeRequest, `/rovers/${serial}/enable_downloading`, 'PATCH', RoverAction.ENABLE_DOWNLOAD)
    yield put(RoverActions.enableDownloadRoverSSHSuccess(serial, rover))
  } catch (e) {
    yield put(RoverActions.enableDownloadRoverSSHFailure(serial, e.message || 'Error ocurred'))
  }
}

function * downloadPublicSSH ({ serial }) {
  try {
    const { data } = yield call(axios.get, `/rovers/${serial}/public_ssh_key`)
    downloadFile(`id_rsa_${serial}.pubk`, data, { type: 'text' })
  } catch (e) {
    toast.error('Error occurred while downloading public SSH key')
  }
}

function * downloadPrivateSSH ({ serial }) {
  try {
    const { data } = yield call(axios.get, `/rovers/${serial}/private_ssh_key`)
    downloadFile(`id_rsa_${serial}.pk`, data, { type: 'text' })
  } catch (e) {
    toast.error('Error occurred while downloading private SSH key')
  }
}

function * downloadRMAReport ({ serial }) {
  try {
    const { data } = yield call(axios.get, `/rovers/${serial}/rma_report`, { responseType: 'arraybuffer' })
    downloadFile(`rma-report-${serial}.pdf`, data, { type: 'application/pdf' })
  } catch (e) {
    toast.error('Error occurred while downloading RMA report')
  }
}

function * downloadCalibrationCertificate ({ event, attachment }) {
  try {
    const { data } = yield call(axios.get, `/rover_calibrations/${event.slug}/certificates/${attachment.id}`)
    downloadFile(attachment.name + '.htm', data, { type: 'text' })
  } catch (e) {
    toast.error('Error occurred while downloading calibration certificate')
  }
}

function * downloadRoverSettings ({ serial }) {
  try {
    if (isReconRover(serial)) {
      const element = document.createElement('a')
      document.body.appendChild(element)
      element.setAttribute('href', `${config.API_BASE_CLIENT}/rovers/${serial}/settings`)
      element.setAttribute('target', `_blank`)
      // element.setAttribute('download', `${fileName}.${fileExtension}`)
      element.style.display = ''
      element.click()
      document.body.removeChild(element)
    } else {
      const roverSettings = yield call(
        makeRequest,
        `${config.API_BASE_CLIENT}/rovers/${serial}/settings`, // ${config.LICENSING_API_BASE_CLIENT}
        'GET',
        undefined,
        undefined,
        false,
      )
      const roversSettingsData = (roverSettings && roverSettings.data) || {}
      downloadFile(`roversettings-${serial}.json`, JSON.stringify(roversSettingsData, null, 2))
    }
  } catch (e) {
    showErrorMessage(e)
    // toast.error('Error occurred while downloading rover settings')
  }
}

function * getRoverEvents ({ serial, events = [], search = '' }) {
  const roverEventUrls = getRoverEventsUrls(serial)
  yield put(RoverActions.getRoverEventsLoading(serial))
  try {
    // If we have `events` properties provided we should keep only them
    const eventTypes = events.length > 0
      ? Object.keys(roverEventUrls).filter(key => events.includes(key))
      : Object.keys(roverEventUrls)

    const tasks = []
    const filteredTasks = []
    const types = []
    const filteredTypes = []
    let filteredRoverEvents = []
    for (const eventType of eventTypes) {
      const url = roverEventUrls[eventType]
      types.push(eventType)
      tasks.push(call(axios.get, url))
    }
    const joinedResults = yield allSettled(tasks)
    const failedTypes = []
    const roverEvents = joinedResults.reduce((allResults, result, index) => {
      if (result.error) {
        const errorOnType = types[index]
        toast.error('Failed to retrieve ' + errorOnType + ' events')
        failedTypes.push(errorOnType)
        return allResults
      }
      const { data: { data: events } } = result.result
      return [
        ...allResults,
        ...events.map(event => convertRawRoverEvent(event, types[index])),
      ]
    }, [])
    if (search) {
      // For searching we should omit `Register` event and omit already failed events
      const eventTypesForSearch = eventTypes.filter(eventType => eventType !== RoverEventType.REGISTER && !failedTypes.includes(eventType))
      for (const eventType of eventTypesForSearch) {
        const url = `${roverEventUrls[eventType]}?keyword=${search}`
        filteredTypes.push(eventType)
        filteredTasks.push(yield fork(axios.get, url))
      }
      const joinedFilteredResults = yield joinAll(filteredTasks)
      filteredRoverEvents = joinedFilteredResults.reduce((allResults, { data: { data: events } }, index) => [
        ...allResults,
        ...events.map(event => convertRawRoverEvent(event, filteredTypes[index])),
      ], [])
    }
    filteredRoverEvents.forEach(event => {
      const index = roverEvents.findIndex(re => re.id === event.id)
      if (index >= 0) {
        roverEvents[index].isFiltered = true
      }
    })
    const [
      { data: { data: calibrations } },
      { data: { data: customSettings } },
      // { data: { data: missions } },
    ] = yield all([
      call(axios.get, `/rovers/${serial}/calibrations`),
      call(axios.get, `/rovers/${serial}/custom_settings`),
      // call(axiosLidarmill.get, `/rovers/${serial}/missions`),
    ])
    const missionEvents = roverEvents
      .filter(event => event.type === RoverEventType.MISSION)
      .filter(missionEvent => !missionEvent.is_deleted)

    const newEvents = [
      ...roverEvents.filter(roverEvent => roverEvent.type !== RoverEventType.MISSION),
      ...roverEvents
        .filter(event => event.type === RoverEventType.MISSION)
        .filter(missionEvent => missionEvent.is_deleted),
      ...missionEvents.map(event => convertRawRoverEvent(event, RoverEventType.MISSION)),
      ...calibrations.map(calibration => convertRawRoverEvent(calibration, RoverEventType.CALIBRATION)),
      ...customSettings.map(customSetting => convertRawRoverEvent(customSetting, RoverEventType.CUSTOM_SETTING)),
      // ...missions.map(mission => convertRawRoverEvent(mission, RoverEventType.MISSION)),
    ]
    yield put(RoverActions.getRoverEventsSuccess(serial, newEvents))
  } catch (e) {
    showErrorMessage(e)
    yield put(RoverActions.getRoverEventsFailure(serial, e.message || 'Error ocurred'))
  }
}

function * getRoverProjects ({ serial, rover }) {
  yield put(RoverActions.getRoverProjectsLoading(serial))
  try {
    const companyId = path(['company', 'id'], rover)
    if (isProductionEnvironment()) {
      // In production we get projects for rover and Cali Bration(calibration@phoenixlidar.com) user
      // from Phoenix LiDAR Systems company. These projects are used when user edit Data Acquisition in the "Project on LIDARMIll" list
      const { data: { data: projects } } = yield call(axiosLidarmill.get, `projects?rover_serial=${serial}&user_id=5db60121-a6c6-4347-bdcb-2bdb5f113a65&exclude=trajectories,gcps,project_thumbnails`)
      if (companyId) {
        const { data: { data: companyProjects } } = yield call(axiosLidarmill.get, `projects?rover_serial=${serial}&company_id=${companyId}&exclude=trajectories,gcps,project_thumbnails`)
        yield put(RoverActions.getRoverProjectsSuccess(serial, projects, companyProjects))
      } else {
        yield put(RoverActions.getRoverProjectsSuccess(serial, projects, []))
      }
    } else {
      // On dev we don't have that user - use projects for rover
      const { data: { data: projects } } = yield call(axiosLidarmill.get, `projects?rover_serial=${serial}&exclude=trajectories,gcps,project_thumbnails`)
      if (companyId) {
        const { data: { data: companyProjects } } = yield call(axiosLidarmill.get, `projects?rover_serial=${serial}&company_id=${companyId}&exclude=trajectories,gcps,project_thumbnails`)
        yield put(RoverActions.getRoverProjectsSuccess(serial, projects, companyProjects))
      } else {
        yield put(RoverActions.getRoverProjectsSuccess(serial, projects, []))
      }
    }
  } catch (e) {
    yield put(RoverActions.getRoverProjectsFailure(serial, e.message || 'Error ocurred'))
  }
}

function * addEventAttachments ({ serial, eventId, eventType, attachments, onSuccess }) {
  yield put(RoverActions.addEventAttachmentsLoading(serial))
  try {
    const eventAttachments = yield call(addAttachments, eventId, eventType, attachments)
    yield put(RoverActions.addEventAttachmentsSuccess(serial, eventId, eventType, eventAttachments))
    const manyDocuments = attachments.length > 1
    toast.success(`Document${manyDocuments ? 's' : ''} ${manyDocuments ? 'have' : 'has'} benn successfully added!`)
    if (onSuccess) {
      onSuccess()
    }
  } catch (e) {
    showErrorMessage(e)
    yield put(RoverActions.addEventAttachmentsFailure(serial, e.message || 'Error ocurred'))
  }
}

function * deleteEventAttachments ({ serial, eventId, eventType, attachmentIds, onSuccess }) {
  yield put(RoverActions.deleteEventAttachmentsLoading(serial))
  try {
    const tasks = []
    for (const attachmentId of attachmentIds) {
      tasks.push(yield fork(axios.delete, `/attachments/${attachmentId}`))
    }
    yield joinAll(tasks)
    yield put(RoverActions.deleteEventAttachmentsSuccess(serial, eventId, eventType, attachmentIds))
    const manyDocuments = attachmentIds.length > 1
    toast.success(`Document${manyDocuments ? 's' : ''} ${manyDocuments ? 'have' : 'has'} been successfully deleted!`)
    if (onSuccess) {
      onSuccess()
    }
  } catch (e) {
    showErrorMessage(e)
    yield put(RoverActions.deleteEventAttachmentsFailure(serial, e.message || 'Error ocurred'))
  }
}

function * addChildEvent ({ serial, parentEventId, parentEventType, eventType, data, successCallback }) {
  yield put(RoverActions.addChildEventLoading(serial))
  try {
    const url = getChildEventsUrls(parentEventType, parentEventId, eventType)
    const parentEventUrl = getRoverEventUrl(parentEventType, parentEventId)
    const { data: { data: eventChild } } = yield call(axios.post, url, omit(['attachments'], data))
    let parentEvent
    if (parentEventUrl) {
      const { data: { data: parentEvent_ } } = yield call(axios.get, parentEventUrl)
      parentEvent = parentEvent_
    }
    const attachments = data.attachments
    const transformedEvent = convertRawRoverEvent(eventChild, eventType)
    if (attachments) {
      const eventAttachments = yield call(addAttachments, transformedEvent.id, eventType, attachments)
      yield put(RoverActions.addChildEventSuccess(serial, parentEventId, parentEventType, eventType, ({ ...transformedEvent, attachments: eventAttachments }), parentEvent))
    } else {
      yield put(RoverActions.addChildEventSuccess(serial, parentEventId, parentEventType, eventType, transformedEvent, parentEvent))
    }
    toast.success('Event has been successfully added!')
    if (successCallback) {
      successCallback()
    }
    /*
    if (eventType === RoverEventType.RMA) {
      yield put(RoverActions.setShowRMADialog(true, transformedEvent.id))
    }
    */
  } catch (e) {
    showErrorMessage(e)
    yield put(RoverActions.addChildEventFailure(serial, e.message || 'Error ocurred'))
  }
}

function transformWorkListStep (workListStep, workLists) {
  // In this case we know that this step is not inside of the child work lists (root work list)
  // So we need to get it from the work list
  if (!workListStep.parentWorkListId) {
    const workList = workLists.find(wl => wl.id === workListStep.workListId)
    return {
      ...workListStep,
      parentWorkListId: workList.parentWorkListId,
    }
  }
  return workListStep
}
// Create all work lists steps
function * createWorkListSteps (workListSteps, eventType, eventId) {
  const tasks = []
  for (const workListStep of workListSteps) {
    tasks.push(yield fork(
      axios.post,
      getRoverEventStepStatusesURL(eventType, eventId),
      {
        work_list: { id: workListStep.workListId },
        parent_work_list: { id: workListStep.parentWorkListId },
        step: { id: workListStep.stepId },
        status: workListStep.work_state,
        comment: workListStep.comment,
      },
    ))
  }
  yield joinAll(tasks)
}
// Change all work lists steps
function * changeWorkListSteps (workListSteps, eventType, eventId) {
  const tasks = []
  for (const workListStep of workListSteps) {
    tasks.push(yield fork(
      axios.patch,
      `/step_statuses/${workListStep.id}`,
      {
        status: workListStep.work_state,
        comment: workListStep.comment,
      },
    ))
  }
  yield joinAll(tasks)
}

function * addRoverEvent ({ companyId, serial, eventType, data, successCallback }) {
  yield put(RoverActions.addRoverEventLoading(serial))
  try {
    const url = getAddRoverEventURL(eventType, serial)
    const attachments = data.attachments
    const company = data.company
    const systemType = data.system_type
    const work_lists = data.work_lists || []
    const event_steps = data.event_steps || []
    const work_lists_steps = data.work_lists_steps || []
    const comments = data.comments || []
    const dataToSend = {
      ...omit(['attachments', 'deactivate_company', 'work_lists', 'work_lists_steps', 'event_steps'], data),
      ...(company && { company: { id: company.id } }),
      ...(systemType && { system_type: { id: systemType.id } }),
      ...(comments && comments.length > 0 && { comments: comments.map(comment => ({ text: comment.text })) }),
    }
    const { data: { data: createdEvent } } = yield call(axios.post, url, dataToSend)
    const eventId = createdEvent.id
    let event = createdEvent
    if (work_lists.length > 0) {
      const { data: { data: createdWorkListContainer } } = yield call(
        axios.post,
        getRoverEventWorkListsURL(eventType, eventId),
        {
          work_lists: work_lists.map(workList => ({ id: workList.id })),
          steps: event_steps.map(eventStep => ({ id: eventStep.id })),
        },
      )
      const createdWorkListsContainer = createdWorkListContainer.work_list
      const createdWorkLists = createdWorkListsContainer.child_work_lists.map(workList =>
        transformRecursiveWorkLists(workList, createdWorkListsContainer.id),
      )
      const transformedWorkListsSteps = work_lists_steps.map(step => transformWorkListStep(step, createdWorkLists))
      yield call(createWorkListSteps, transformedWorkListsSteps, eventType, eventId)
      // After creating steps we should update our event as well
      const parentEventUrl = getRoverEventUrl(eventType, eventId)
      if (parentEventUrl) {
        const { data: { data: updatedEvent } } = yield call(axios.get, parentEventUrl)
        event = updatedEvent
      }
    }
    // Special case for Sale events
    if (eventType === RoverEventType.SALE && 'subscriptions' in data && data.subscriptions.length > 0) {
      const tasks = []
      const subscriptions = data.subscriptions
      for (const subscriptionId of subscriptions) {
        tasks.push(yield fork(axios.put, `/subscriptions/${subscriptionId}`, { company: { id: data.company.id } }))
      }
      yield joinAll(tasks)
      yield put(CompaniesActions.getCompanySubscriptions(companyId))
    }
    const transformedEvent = convertRawRoverEvent(event, eventType)
    if (attachments) {
      const eventAttachments = yield call(
        addAttachments,
        transformedEvent.id,
        eventType,
        attachments,
      )
      yield put(RoverActions.addRoverEventSuccess(serial, eventType, ({ ...transformedEvent, attachments: eventAttachments })))
    } else {
      yield put(RoverActions.addRoverEventSuccess(serial, eventType, transformedEvent))
    }
    if (eventType === RoverEventType.SYSTEM_TYPE || eventType === RoverEventType.SALE || eventType === RoverEventType.RENT) {
      const newRoverData = {
        ...(company && { company: company }),
        ...(systemType && { system_type: systemType }),
      }
      yield put(RoverActions.updateRoverSuccess(serial, newRoverData))
    }
    toast.success('Rover event has been successfully added!')
    /*
    if (eventType === RoverEventType.RMA) {
      yield put(RoverActions.setShowRMADialog(true, transformedEvent.id))
    }
    */
    if (data.deactivate_company === true) {
      yield put(CompaniesActions.updateCompany(
        companyId,
        {
          is_active: false,
          deactivating_reason: 'company has no active subscriptions',
        },
        false,
      ))
    }
    if (successCallback) {
      successCallback()
    }
  } catch (e) {
    showErrorMessage(e)
    yield put(RoverActions.addRoverEventFailure(serial, e.message || 'Error ocurred'))
  }
}

// Create all sensors settings
function * createSensorsSettings (sensorsSettings, eventId) {
  try {
    const tasks = []
    for (const sensorSettings of sensorsSettings) {
      tasks.push(yield fork(
        axios.post,
        `/data_acquisition_request_events/${eventId}/sensors_settings`,
        sensorSettings,
      ))
    }
    const result = yield joinAll(tasks)
    return result.map(res => res.data.data)
  } catch (e) {
    toast.error('Something went wrong during creating sensors settings!')
    return []
  }
}
// Change all sensors settings
function * changeSensorsSettings (sensorsSettings) {
  try {
    const tasks = []
    for (const sensorSettings of sensorsSettings) {
      tasks.push(yield fork(
        axios.patch,
        `/sensors_settings/${sensorSettings.id}`,
        omit(['id'], sensorSettings),
      ))
    }
    const result = yield joinAll(tasks)
    return result.map(res => res.data.data)
  } catch (e) {
    toast.error('Something went wrong during changing sensors settings!')
    return []
  }
}

// Create all comments for RMA
function * createComments (comments, eventId) {
  try {
    const tasks = []
    for (const comment of comments) {
      tasks.push(yield fork(
        axios.post,
        `/rmas/${eventId}/rma_comments`,
        comment,
      ))
    }
    const result = yield joinAll(tasks)
    return result.map(res => res.data.data)
  } catch (e) {
    toast.error('Something went wrong during creating comments!')
    return []
  }
}
// Change all sensors settings
function * changeComments (comments) {
  try {
    const tasks = []
    for (const comment of comments) {
      tasks.push(yield fork(
        axios.patch,
        `/rma_comments/${comment.id}`,
        omit(['id'], comment),
      ))
    }
    const result = yield joinAll(tasks)
    return result.map(res => res.data.data)
  } catch (e) {
    toast.error('Something went wrong during changing comments!')
    return []
  }
}

function * editEvent ({ event, serial, eventId, eventType, data, onSuccess }) {
  yield put(RoverActions.editEventLoading())
  try {
    const url = getEditRoverEventUrl(eventId, eventType)
    const attachments = data.attachments
    const check_list_steps = data.check_list_steps || []
    const work_lists_steps = data.work_lists_steps || []
    const sensors_settings = data.sensors_settings || []
    const comments = data.comments || []
    const company = data.company
    const dataToSend = {
      ...omit(['attachments', 'check_list_steps', 'work_lists_steps', 'sensors_settings', 'comments'], data),
      ...(company && { company: { id: company.id } }),
    }
    let newEvent = { ...event }
    if (comments.length > 0) {
      const creatableComments = comments.filter(comment => !('id' in comment))
      const changableComments = comments.filter(comment => 'id' in comment)
      let createdComments = []
      let changedComments = []
      if (creatableComments.length > 0) {
        createdComments = yield call(createComments, creatableComments, eventId)
      }
      if (changableComments.length > 0) {
        changedComments = yield call(changeComments, changableComments)
      }
      if (Object.keys(dataToSend).length <= 0) {
        newEvent.comments = [
          ...(event.comments || []).map(comment => {
            const updatedComment = findById(comment.id, changedComments)
            return updatedComment || comment
          }),
          ...createdComments,
        ]
      }
    }
    if (sensors_settings.length > 0) {
      const creatableSensorsSettings = sensors_settings.filter(sensorSettings => !('id' in sensorSettings))
      const changableSensorsSettings = sensors_settings.filter(sensorSettings => 'id' in sensorSettings)
      let createdSensorsSettings = []
      let changedSensorsSettings = []
      if (creatableSensorsSettings.length > 0) {
        createdSensorsSettings = yield call(createSensorsSettings, creatableSensorsSettings, eventId)
      }
      if (changableSensorsSettings.length > 0) {
        changedSensorsSettings = yield call(changeSensorsSettings, changableSensorsSettings)
      }
      if (Object.keys(dataToSend).length <= 0) {
        newEvent.sensors_settings = [
          ...(event.sensors_settings || []).map(sensorSetting => {
            const updatedSensorSetting = findById(sensorSetting.id, changedSensorsSettings)
            return updatedSensorSetting || sensorSetting
          }),
          ...createdSensorsSettings,
        ]
      }
    }
    if (work_lists_steps.length > 0) {
      const creatableWorkListSteps = work_lists_steps.filter(workListStep => !workListStep.isAdded)
      const changableWorkListSteps = work_lists_steps.filter(workListStep => workListStep.isAdded)
      if (creatableWorkListSteps.length > 0) {
        yield call(createWorkListSteps, creatableWorkListSteps, eventType, eventId)
      }
      if (changableWorkListSteps.length > 0) {
        yield call(changeWorkListSteps, changableWorkListSteps, eventType, eventId)
      }
      if (Object.keys(dataToSend).length <= 0) {
        // After updating steps we should update our event as well
        const parentEventUrl = getRoverEventUrl(eventType, eventId)
        if (parentEventUrl) {
          const { data: { data: updatedEvent } } = yield call(axios.get, parentEventUrl)
          newEvent = convertRawRoverEvent(updatedEvent, eventType)
        }
      }
    }
    if (Object.keys(dataToSend).length > 0) {
      const { data: { data: updatedEvent } } = yield call(axios.patch, url, dataToSend)
      newEvent = convertRawRoverEvent(updatedEvent, eventType)
    }
    if (attachments && attachments.length > 0) {
      const eventAttachments = yield call(
        addAttachments,
        newEvent.slug || newEvent.id,
        eventType,
        attachments,
      )
      newEvent.attachments = [
        ...eventAttachments.map(convertRawAttachment),
        ...(newEvent.attachments || []),
      ]
    }
    yield put(RoverActions.editEventSuccess(serial, eventId, eventType, newEvent))
    if (check_list_steps.length > 0) {
      const tasks = []
      for (const step of check_list_steps) {
        tasks.push(yield fork(axios.patch, `/rma_checklist_steps/${step.id}`, keepProps(['comment', 'work_state'], step)))
      }
      yield joinAll(tasks)
    }
    if (eventType === RoverEventType.RENT) {
      const newRoverData = {
        ...(company && { company: company }),
      }
      yield put(RoverActions.updateRoverSuccess(serial, newRoverData))
    }
    if (onSuccess) {
      onSuccess()
    }
    toast.success('Event has been successfully updated!')
    // yield put(RoverActions.editEventSuccess(serial, eventId, eventType, event))
  } catch (e) {
    showErrorMessage(e)
    yield put(RoverActions.editEventFailure(serial, e.message || e))
  }
}

function * deleteEvent ({ serial, eventId, eventType, onSuccess }) {
  yield put(RoverActions.deleteEventLoading())
  try {
    const url = getDeleteRoverEventUrl(eventId, eventType)
    yield call(axios.delete, url)
    if (onSuccess) {
      onSuccess()
    }
    toast.success('Event has been successfully deleted!')
    yield put(RoverActions.deleteEventSuccess(serial, eventId))
  } catch (e) {
    showErrorMessage(e)
    yield put(RoverActions.deleteEventFailure(serial, e.message || e))
  }
}

// Get reports for selected system
function * getSystemReports ({ serial, pageSize = 50, page = 0, withLoading = true }) {
  yield put(RoverActions.getSystemReportsLoading(serial))
  try {
    const { data: system } = yield call(axiosComponentInfo.get, `/systems/${serial && serial.toLowerCase().startsWith('r') ? serial : `al${serial}`}/`)
    const { data: reports } = yield call(axiosComponentInfo.get, `/systems/${system.id}/reports/?page_size=${pageSize}&page=${page + 1}`)
    // const system = systemJson
    // const reports = reportsJson
    yield put(RoverActions.getSystemReportsSuccess(serial, reports.results, { total: reports.count }))
  } catch (e) {
    yield put(RoverActions.getSystemReportsFailure(serial, e.message || e))
  }
}

// Get components for selected report
function * getReportComponents ({ reportId, withLoading = true }) {
  if (withLoading) yield put(RoverActions.getReportComponentsLoading(reportId))
  try {
    const { data } = yield call(axiosComponentInfo.get, `/reports/${reportId}/report_components/`)
    // const data = componentsJson
    yield put(RoverActions.getReportComponentsSuccess(data, reportId))
  } catch (e) {
    yield put(RoverActions.getReportComponentsFailure(e.message || e, reportId))
  }
}

// Approve register event
function * approveRegisterEvent ({ serial, eventId, data, onSuccess }) {
  yield put(RoverActions.approveEventLoading())
  const eventType = data.is_sale ? RoverEventType.SALE : RoverEventType.RENT
  try {
    const { data: { data: event } } = yield call(axios.post, `/register_events/${eventId}/approve`, data)
    yield put(RoverActions.approveEventSuccess(serial, eventId, event, eventType))
    if (onSuccess) {
      onSuccess()
    }
    if (eventType === RoverEventType.SALE) {
      const newRoverData = {
        company: event.company,
      }
      yield put(RoverActions.updateRoverSuccess(serial, newRoverData))
    }
    toast.success('Event has been successfully approved!')
  } catch (e) {
    showErrorMessage(e)
    yield put(RoverActions.approveEventFailure(e.message || e))
  }
}
// Approve event
function * approveEvent ({ serial, eventId, eventType, data = {}, onSuccess }) {
  yield put(RoverActions.approveEventLoading())
  const map = {
    [RoverEventType.CALIBRATION]: `/rover_calibrations/${eventId}/approve`,
  }
  try {
    const url = map[eventType]
    const { data: { data: event } } = yield call(axios.post, url, data)
    yield put(RoverActions.approveEventSuccess(serial, eventId, event, eventType))
    if (onSuccess) {
      onSuccess()
    }
    toast.success('Event has been successfully approved!')
  } catch (e) {
    showErrorMessage(e)
    yield put(RoverActions.approveEventFailure(e.message || e))
  }
}
// Disapprove event
function * disapproveEvent ({ serial, eventId, eventType, data = {}, onSuccess }) {
  yield put(RoverActions.disapproveEventLoading())
  const map = {
    [RoverEventType.CALIBRATION]: `/rover_calibrations/${eventId}/disapprove`,
  }
  try {
    const url = map[eventType]
    const { data: { data: event } } = yield call(axios.post, url, data)
    yield put(RoverActions.disapproveEventSuccess(serial, eventId, event, eventType))
    if (onSuccess) {
      onSuccess()
    }
    toast.success('Event has been successfully disapproved!')
  } catch (e) {
    showErrorMessage(e)
    yield put(RoverActions.disapproveEventFailure(e.message || e))
  }
}
function * rejectEvent ({ serial, eventId, eventType, data = {}, onSuccess }) {
  yield put(RoverActions.rejectEventLoading())
  try {
    const url = getEditRoverEventUrl(eventId, eventType)
    const { data: { data: event } } = yield call(axios.patch, url, { ...data, is_rejected: true })
    yield put(RoverActions.rejectEventSuccess(serial, eventId, event, eventType))
    if (onSuccess) {
      onSuccess()
    }
    toast.success('Event has been successfully rejected!')
  } catch (e) {
    showErrorMessage(e)
    yield put(RoverActions.rejectEventFailure(e.message || e))
  }
}
// Close event
function * closeEvent ({ serial, eventId, eventType, closed_at, onSuccess }) {
  yield put(RoverActions.closeEventLoading())
  try {
    const { data: { data: event } } = yield call(axios.patch, `/rmas/${eventId}`, { is_closed: true, closed_at: closed_at })
    yield put(RoverActions.closeEventSuccess(serial, eventId, event, eventType))
    if (onSuccess) {
      onSuccess()
    }
    toast.success('RMA has been successfully closed!')
  } catch (e) {
    showErrorMessage(e)
    yield put(RoverActions.closeEventFailure(e.message || e))
  }
}
// Register user system
function * registerSystem ({ companyId, data, onSuccess }) {
  yield put(RoverActions.registerSystemLoading())
  try {
    const { data: { data: event } } = yield call(axios.post, `/companies/${companyId}/rover_register_events`, data)
    yield put(RoverActions.registerSystemSuccess(companyId, event))
    if (onSuccess) {
      onSuccess()
    }
    toast.success('System has been successfully registered!')
  } catch (e) {
    showErrorMessage(e)
    yield put(RoverActions.registerSystemFailure(e.message || e))
  }
}
// Get event db logs
function * getEventDBLogs ({ eventId, eventType, pagination, onSuccess }) {
  const { search = '', pageSize = 100, page = 1, orderBy = 'timestamp', order = 'desc' } = pagination
  yield put(RoverActions.getEventDBLogsLoading())
  try {
    const url = getEventDBLogsUrl(eventId, eventType)
    const { data: { data: logs, pagination } } = yield call(axios.get, `${url}/${createQueryString({ search, pageSize, page, order, orderBy })}`)
    yield put(RoverActions.getEventDBLogsSuccess(eventId, eventType, logs, getPaginationFromResponse(pagination.total, page, pageSize)))
    if (onSuccess) {
      onSuccess()
    }
  } catch (e) {
    showErrorMessage(e)
    yield put(RoverActions.getEventDBLogsFailure(e.message || e))
  }
}
// Get event db logs
function * getCheckListStepDBLogs ({ eventType, stepId, pagination, onSuccess }) {
  const { search = '', pageSize = 100, page = 1, orderBy = 'timestamp', order = 'desc' } = pagination
  yield put(RoverActions.getCheckListStepDBLogsLoading())
  try {
    const url = getCheckListStepDBLogsUrl(eventType, stepId)
    const { data: { data: logs, pagination } } = yield call(axios.get, `${url}/${createQueryString({ search, pageSize, page, order, orderBy })}`)
    yield put(RoverActions.getCheckListStepDBLogsSuccess(eventType, stepId, logs, getPaginationFromResponse(pagination.total, page, pageSize)))
    if (onSuccess) {
      onSuccess()
    }
  } catch (e) {
    showErrorMessage(e)
    yield put(RoverActions.getCheckListStepDBLogsFailure(e.message || e))
  }
}
function * addEventStatus ({ serial, eventId, eventType, data, notifyUsers, onSuccess }) {
  yield put(RoverActions.addEventStatusLoading())
  try {
    const url = getStatusEventUrl(eventId, eventType)
    const { data: { data: event } } = yield call(axios.post, url, { ...data, users_to_notify: notifyUsers })
    yield put(RoverActions.addEventStatusSuccess(serial, eventId, eventType, event))
    if (onSuccess) {
      onSuccess()
      toast.success('Status has been successfully updated!')
    }
  } catch (e) {
    showErrorMessage(e)
    yield put(RoverActions.addEventStatusFailure(e.message || e))
  }
}
function * getRoversCSV () {
  yield put(RoverActions.getRoversCSVLoading())
  try {
    const { data: csv } = yield call(axios.get, `/rovers/csv`)
    yield put(RoverActions.getRoversCSVSuccess(csv))
  } catch (e) {
    showErrorMessage(e)
    yield put(RoverActions.getRoversCSVFailure(e.message || e))
  }
}
function * getRoverUpdates ({ serial }) {
  yield put(RoverActions.getRoverUpdatesLoading(serial))
  try {
    const { data: { data: updates } } = yield call(axios.get, `/rovers/${serial}/software_updates`)
    yield put(RoverActions.getRoverUpdatesSuccess(serial, updates))
  } catch (e) {
    showErrorMessage(e)
    yield put(RoverActions.getRoverUpdatesFailure(serial, e.message || e))
  }
}
function * getRoverVersion ({ serial }) {
  yield put(RoverActions.getRoverVersionLoading(serial))
  try {
    const { data: { data: firmware_version } } = yield call(axios.get, `/rovers/${serial}/component_info_details`)
    yield put(RoverActions.getRoverVersionSuccess(serial, firmware_version))
  } catch (e) {
    showErrorMessage(e)
    yield put(RoverActions.getRoverVersionFailure(serial, e.message || e))
  }
}

/**
 * Check the creatibility of a DAR event.
 *
 * @param {string} serial - The serial number of the rover.
 * @param {string} eventType - The type of event to check for creatibility.
 */
function * getDarEventCreatibility ({ serial, eventType }) {
  yield put(RoverActions.getDarEventCreatibilityLoading(serial))
  try {
    const { data: { data: messages } } = yield call(axios.post, `/rovers/${serial}/data_acquisition_request_events/check_createbilities`, { event_type: eventType })
    yield put(RoverActions.getDarEventCreatibilitySuccess(serial, eventType, messages))
  } catch (e) {
    showErrorMessage(e)
    yield put(RoverActions.getDarEventCreatibilityFailure(serial, e.message || e))
  }
}

/**
 * Fetch a build event by serial and event ID.
 *
 * @param {string} serial - The serial number associated with the build event.
 * @param {string} eventId - The unique identifier of the build event.
 */
function * getBuildEvent ({ serial, eventId }) {
  yield put(RoverActions.getBuildEventLoading())
  try {
    const { data: { data: event } } = yield call(axios.get, `/build_events/${eventId}`)
    yield put(RoverActions.getBuildEventSuccess(serial, eventId, event))
  } catch (e) {
    yield put(RoverActions.getBuildEventLoading(e.message || 'Error ocurred'))
  }
}

function * getRoversWatcher () {
  yield takeLatest(RoverTypes.GET_ROVERS, getRovers)
}
function * getRoversCSVWatcher () {
  yield takeLatest(RoverTypes.GET_ROVERS_CSV, getRoversCSV)
}
function * getAllRoversWatcher () {
  yield takeLatest(RoverTypes.GET_ALL_ROVERS, getAllRovers)
}
function * addRoverWatcher () {
  yield takeLatest(RoverTypes.ADD_ROVER, addRover)
}
function * editRoverWatcher () {
  yield takeLatest(RoverTypes.EDIT_ROVER, editRover)
}
function * enableDownloadRoverSSHWatcher () {
  yield takeLatest(RoverTypes.ENABLE_DOWNLOAD_ROVER_SSH, enableDownloadRoverSSH)
}
function * downloadPublicSSHWatcher () {
  yield takeLatest(RoverTypes.DOWNLOAD_PUBLIC_SSH, downloadPublicSSH)
}
function * downloadPrivateSSHWatcher () {
  yield takeLatest(RoverTypes.DOWNLOAD_PRIVATE_SSH, downloadPrivateSSH)
}
function * downloadRoverSettingsWatcher () {
  yield takeLatest(RoverTypes.DOWNLOAD_ROVER_SETTINGS, downloadRoverSettings)
}
function * downloadRMAReportWatcher () {
  yield takeLatest(RoverTypes.DOWNLOAD_RMA_REPORT, downloadRMAReport)
}
function * downloadCalibrationCertificateWatcher () {
  yield takeLatest(RoverTypes.DOWNLOAD_CALIBRATION_CERTIFICATE, downloadCalibrationCertificate)
}
function * addRoverEventWatcher () {
  yield takeLatest(RoverTypes.ADD_ROVER_EVENT, addRoverEvent)
}
function * editEventWatcher () {
  yield takeLatest(RoverTypes.EDIT_EVENT, editEvent)
}
function * deleteEventWatcher () {
  yield takeLatest(RoverTypes.DELETE_EVENT, deleteEvent)
}
function * getRoverEventsWatcher () {
  yield takeLatest(RoverTypes.GET_ROVER_EVENTS, getRoverEvents)
}
function * getSystemReportsWatcher () {
  yield takeLatest(RoverTypes.GET_SYSTEM_REPORTS, getSystemReports)
}
function * getReportComponentsWatcher () {
  yield takeLatest(RoverTypes.GET_REPORT_COMPONENTS, getReportComponents)
}
function * addChildEventWatcher () {
  yield takeLatest(RoverTypes.ADD_CHILD_EVENT, addChildEvent)
}
function * approveRegisterEventWatcher () {
  yield takeLatest(RoverTypes.APPROVE_REGISTER_EVENT, approveRegisterEvent)
}
function * approveEventWatcher () {
  yield takeLatest(RoverTypes.APPROVE_EVENT, approveEvent)
}
function * disapproveEventWatcher () {
  yield takeLatest(RoverTypes.DISAPPROVE_EVENT, disapproveEvent)
}
function * rejectEventWatcher () {
  yield takeLatest(RoverTypes.REJECT_EVENT, rejectEvent)
}
function * registerSystemWatcher () {
  yield takeLatest(RoverTypes.REGISTER_SYSTEM, registerSystem)
}
function * closeEventWatcher () {
  yield takeLatest(RoverTypes.CLOSE_EVENT, closeEvent)
}
function * addEventAttachmentsWatcher () {
  yield takeLatest(RoverTypes.ADD_EVENT_ATTACHMENTS, addEventAttachments)
}
function * deleteEventAttachmentsWatcher () {
  yield takeLatest(RoverTypes.DELETE_EVENT_ATTACHMENTS, deleteEventAttachments)
}
function * getEventDBLogsWatcher () {
  yield takeLatest(RoverTypes.GET_EVENT_DB_LOGS, getEventDBLogs)
}
function * getCheckListStepDBLogsWatcher () {
  yield takeLatest(RoverTypes.GET_CHECK_LIST_STEP_DB_LOGS, getCheckListStepDBLogs)
}
function * getRoverProjectsWatcher () {
  yield takeLatest(RoverTypes.GET_ROVER_PROJECTS, getRoverProjects)
}
function * addEventStatusWatcher () {
  yield takeLatest(RoverTypes.ADD_EVENT_STATUS, addEventStatus)
}
function * getRoverUpdatesWatcher () {
  yield takeLatest(RoverTypes.GET_ROVER_UPDATES, getRoverUpdates)
}
function * getRoverVersionWatcher () {
  yield takeLatest(RoverTypes.GET_ROVER_VERSION, getRoverVersion)
}
function * getDarEventCreatibilityWatcher () {
  yield takeLatest(RoverTypes.GET_DAR_EVENT_CREATIBILITY, getDarEventCreatibility)
}

function * getBuildEventWatcher () {
  yield takeLatest(RoverTypes.GET_BUILD_EVENT, getBuildEvent)
}

export default function * root () {
  yield fork(getRoversWatcher)
  yield fork(getRoversCSVWatcher)
  yield fork(getAllRoversWatcher)
  yield fork(addRoverWatcher)
  yield fork(editRoverWatcher)
  yield fork(enableDownloadRoverSSHWatcher)
  yield fork(downloadPublicSSHWatcher)
  yield fork(downloadPrivateSSHWatcher)
  yield fork(downloadRoverSettingsWatcher)
  yield fork(downloadRMAReportWatcher)
  yield fork(getRoverProjectsWatcher)
  yield fork(getRoverUpdatesWatcher)
  yield fork(getRoverVersionWatcher)
  // Rover custom settings
  yield fork(getRoverCustomSettingsWatcher)
  yield fork(addRoverCustomSettingWatcher)
  // Rover calibrations
  yield fork(getRoverCalibrationsWatcher)
  yield fork(editRoverCalibrationWatcher)
  yield fork(deleteRoverCalibrationWatcher)
  // Rover events
  yield fork(getRoverEventsWatcher)
  yield fork(addRoverEventWatcher)
  yield fork(addChildEventWatcher)
  yield fork(approveRegisterEventWatcher)
  yield fork(approveEventWatcher)
  yield fork(disapproveEventWatcher)
  yield fork(rejectEventWatcher)
  yield fork(registerSystemWatcher)
  yield fork(addEventAttachmentsWatcher)
  yield fork(deleteEventAttachmentsWatcher)
  yield fork(closeEventWatcher)
  yield fork(editEventWatcher)
  yield fork(deleteEventWatcher)
  yield fork(downloadCalibrationCertificateWatcher)
  yield fork(getEventDBLogsWatcher)
  yield fork(getCheckListStepDBLogsWatcher)
  yield fork(addEventStatusWatcher)
  yield fork(getDarEventCreatibilityWatcher)
  yield fork(getBuildEventWatcher)
  // Componentinfo
  yield fork(getSystemReportsWatcher)
  yield fork(getReportComponentsWatcher)
}
