import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isUserAdminOrPLSUser } from 'utils/company'
import { getLoggedUser } from 'modules/users/selectors'

/**
 * AdminOnlyComponent is a wrapper component that renders its children
 * only if the user is an Admin or PLS User.
 *
 * @param {boolean} props.isAdminOrPLSUser - Determines if the user is an Admin or PLS User
 * @param {React.ReactNode} props.children - The children components to be rendered
 * @returns {React.ReactNode|null} Rendered children if the user is an Admin or PLS User, otherwise null
 */
const AdminOnlyComponent = ({ children, isAdminOrPLSUser }) => {
  if (!isAdminOrPLSUser) {
    return null
  }
  return <>{children}</>
}

const mapStateToProps = state => ({
  isAdminOrPLSUser: isUserAdminOrPLSUser(getLoggedUser(state)),
})

AdminOnlyComponent.propTypes = {
  children: PropTypes.any,
  isAdminOrPLSUser: PropTypes.bool,
}

export default connect(mapStateToProps)(AdminOnlyComponent)
