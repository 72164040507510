import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, withRouter, Redirect as RedirectRoute } from 'react-router-dom'
import { connect } from 'react-redux'
import { path } from 'ramda'
import Loadable from 'react-loadable'
import { withNamespaces } from 'react-i18next'
// Project deps
import withRoot from 'HOC/withRoot'
import PageLoader from 'components/PageLoader'
import AcceptTermsDialog from 'components/dialogs/AcceptTermsDialog'
import AcceptUpdateDialog from 'components/dialogs/AcceptUpdateDialog'
import AppActions from 'modules/app/actions'
import RoversActions from 'modules/rovers/actions'
import BillingAgreementActions from 'modules/billingAgreement/actions'
import CompaniesActions from 'modules/companies/actions'
import UsersActions from 'modules/users/actions'
import UrlActions from 'modules/url/actions'
import { isAdmin, isLoggedIn, getUsers, getLoggedUser, isAcceptTermsFormOpen } from 'modules/users/selectors'
import { createUrl } from 'utils/url'
import { checkVersion } from 'utils/clear'
import { getCompanyId, isUserCompanyCustomer, isUserAdminOrPLSUser } from 'utils/company'
// Local deps
// import AdminOrEmployeeRoute from './AdminOrEmployeeRoute'
import PrivateRoute from './PrivateRoute'
import RegisterRoute from './RegisterRoute'
import AdminRoute from './AdminRoute'
import AdminOrPLSRoute from './AdminOrPLSRoute'
// Pages
import ErrorPage from 'components/pages/Error'
import config, { lidarmillCookies } from 'config'
import { isConstantsLoaded } from 'modules/app/selectors'
import Check2FADialog from 'components/dialogs/Check2FADialog'
import RegisterSystemDialog from 'components/Licensing/RegisterSystemDialog'
import RoverUpdates from 'components/pages/RoverUpdates'
import { getCheckoutSessionId, getCheckoutSessionStatus, getCheckoutSessionTimeout } from 'modules/billingAgreement/selectors'
const Dashboard = Loadable({ loader: () => import('components/pages/Dashboard'), loading: PageLoader, delay: 50 })
const Login = Loadable({ loader: () => import('components/pages/Login'), loading: PageLoader, delay: 50 })
const RegisterSuccess = Loadable({ loader: () => import('components/pages/RegisterSuccess'), loading: PageLoader, delay: 50 })
const CompanyUserRegisterSuccess = Loadable({ loader: () => import('components/pages/CompanyUserRegisterSuccess'), loading: PageLoader, delay: 50 })
const EmployeeRegister = Loadable({ loader: () => import('components/pages/EmployeeRegister'), loading: PageLoader, delay: 50 })
const Register = Loadable({ loader: () => import('components/pages/Register'), loading: PageLoader, delay: 0 })
const RegisterSystem = Loadable({ loader: () => import('components/pages/RegisterSystem'), loading: PageLoader, delay: 0 })
const Recover = Loadable({ loader: () => import('components/pages/Recover'), loading: PageLoader, delay: 50 })
const LostPassword = Loadable({ loader: () => import('components/pages/LostPassword'), loading: PageLoader, delay: 50 })
const Redirect = Loadable({ loader: () => import('components/pages/Redirect'), loading: PageLoader, delay: 50 })
const ExternalRedirect = Loadable({ loader: () => import('components/pages/ExternalRedirect'), loading: PageLoader, delay: 50 })
const User = Loadable({ loader: () => import('components/pages/User'), loading: PageLoader, delay: 50 })
const Company = Loadable({ loader: () => import('components/pages/Company'), loading: PageLoader, delay: 50 })
const CancelSession = Loadable({ loader: () => import('components/pages/CancelSession'), loading: PageLoader, delay: 50 })
const SuccessSession = Loadable({ loader: () => import('components/pages/SuccessSession'), loading: PageLoader, delay: 50 })
const Subscription = Loadable({ loader: () => import('components/pages/Subscription'), loading: PageLoader, delay: 50 })
const Users = Loadable({ loader: () => import('components/pages/Users'), loading: PageLoader, delay: 50 })
const SalesProducts = Loadable({ loader: () => import('components/pages/SalesProducts'), loading: PageLoader, delay: 50 })
const Terms = Loadable({ loader: () => import('components/pages/Terms/index'), loading: PageLoader, delay: 50 })
const ProductReleases = Loadable({ loader: () => import('components/pages/ProductReleases'), loading: PageLoader, delay: 50 })
const Statistics = Loadable({ loader: () => import('components/pages/Statistics'), loading: PageLoader, delay: 50 })
const SpatialExplorerCrashes = Loadable({ loader: () => import('components/pages/SpatialExplorerCrashes'), loading: PageLoader, delay: 50 })
const Rovers = Loadable({ loader: () => import('components/pages/Rovers'), loading: PageLoader, delay: 50 })
const SystemTypes = Loadable({ loader: () => import('components/pages/SystemTypes'), loading: PageLoader, delay: 50 })
const Recalls = Loadable({ loader: () => import('components/pages/Recalls'), loading: PageLoader, delay: 50 })
const Logs = Loadable({ loader: () => import('components/pages/Logs'), loading: PageLoader, delay: 50 })
const DAEvents = Loadable({ loader: () => import('components/pages/DAEvents'), loading: PageLoader, delay: 50 })
const Notifications = Loadable({ loader: () => import('components/pages/Notifications'), loading: PageLoader, delay: 50 })
const ExpiredSubscriptions = Loadable({ loader: () => import('components/pages/ExpiredSubscriptions'), loading: PageLoader, delay: 50 })
const DARequestEvents = Loadable({ loader: () => import('components/pages/DARequestEvents'), loading: PageLoader, delay: 50 })
const RMA = Loadable({ loader: () => import('components/pages/RMA'), loading: PageLoader, delay: 50 })
const Rents = Loadable({ loader: () => import('components/pages/Rents'), loading: PageLoader, delay: 50 })
const EmailConfirmation = Loadable({ loader: () => import('components/pages/EmailConfirmation'), loading: PageLoader, delay: 50 })

class App extends React.Component {
  state = {
    historyListener: null,
    updateDialogOpen: false,
  }

  componentDidMount () {
    const {
      history,
      onUrlChange,
      isLoggedIn,
      userId,
      getMe,
      getUserReleases,
      getSalesProducts,
      getConstants,
      getProducts,
      setRedirectURL,
    } = this.props
    this.setState({
      historyListener: history.listen(location => {
        onUrlChange(createUrl(location))
      }),
    })
    const urlParams = new URLSearchParams(history.location.search)
    const backToLm = urlParams.get('backToLm')

    checkVersion(() => {
      console.log('checking version')
      if (backToLm) {
        setRedirectURL(`${config.LIDARMILL_BASE}/dashboard/projects`)
      }
      // when users log out of LM they are redirected to Accounts '/redirect' page and will be logged out of Accounts as well.
      // In this case, we don't need to make all these requests. getMe() will be enough.
      if (isLoggedIn && history.location.pathname === '/redirect') {
        getMe()
      } else if (isLoggedIn && userId) {
        this.update()
        getMe()
        getUserReleases()
        getSalesProducts()
        this.checkAcceptedForms()
      }
    })
    getConstants()
    getProducts()
    this.updateSubscriptionStatusInterval()
  }

  checkAcceptedForms = () => {
    setTimeout(() => this.checkAcceptedTerms(), 1000)
    // setTimeout(() => this.checkAcceptedUpdate(), 1000)
  }

  // Updating subscription status every 5 minutes
  updateSubscriptionStatusInterval = () => {
    if (this.subscriptionInterval) {
      clearInterval(this.subscriptionInterval)
    }
    const self = this
    this.subscriptionInterval = setInterval(() => {
      self.updateSubscriptionStatus(false)
    }, 5 * 60 * 1000)
  }

  // Updating checkout session
  updateCheckoutStatusInterval = (sessionId, sessionState, timeout) => {
    if (this.checkoutSessionInterval) {
      clearInterval(this.checkoutSessionInterval)
    }
    const self = this
    if (sessionId) {
      this.checkoutSessionInterval = setInterval(() => {
        self.props.getCheckoutSession(sessionId, sessionState)
      }, timeout)
    }
  }

  componentDidUpdate (prevProps) {
    const { user, getMe, getSalesProducts, getUserReleases, userId, companyId } = this.props
    if (
      prevProps.checkoutSessionId !== this.props.checkoutSessionId ||
      prevProps.checkoutSessionState !== this.props.checkoutSessionState ||
      prevProps.checkoutSessionTimeout !== this.props.checkoutSessionTimeout
    ) {
      this.updateCheckoutStatusInterval(this.props.checkoutSessionId, this.props.checkoutSessionState, this.props.checkoutSessionTimeout)
    }
    if (user) {
      if (userId && userId !== prevProps.userId) {
        this.update()
        getUserReleases()
        getMe()
        getSalesProducts()
        this.checkAcceptedForms()
      }
      if (
        ((userId && userId !== prevProps.userId) || companyId !== prevProps.companyId) ||
        (user.has_ongoing_subscription !== path(['user', 'has_ongoing_subscription'], prevProps))
      ) {
        this.updateSubscriptionStatusInterval()
      }
    }
  }

  componentWillUnmount () {
    if (this.subscriptionInterval) clearInterval(this.subscriptionInterval)
  }

  update = () => {
    const {
      companyId,
      isUserAdminOrPls,
      getCompanyUsers,
      getCompanyRovers,
      getAllSystemTypes,
      getTags,
      isUserCustomer,
      getUsers,
      getCompanies,
      getULData,
      getAllProjects,
      getAllRovers,
      getDAREventTypes,
      getRecallTypes,
      getDAEventIssueTypes,
      getCheckListSteps,
      getRMAStatuses,
      getSystemTypeStatuses,
      getCustomerTypes,
    } = this.props
    if (!isUserCustomer) {
      getCompanyUsers(companyId)
      getCompanyRovers(companyId)
    }
    this.updateSubscriptionStatus()
    getULData()
    getAllProjects()
    getDAREventTypes()
    getRecallTypes()
    getDAEventIssueTypes()
    getCheckListSteps()
    getSystemTypeStatuses()
    getRMAStatuses()
    getCustomerTypes()
    if (isUserAdminOrPls) {
      getAllRovers()
      getTags()
      getAllSystemTypes()
      getUsers()
      getCompanies()
    }
  }

  updateSubscriptionStatus = withLoading => {
    const { companyId, getSubscriptions } = this.props
    if (companyId) getSubscriptions(companyId, withLoading)
  }

  checkAcceptedTerms = () => {
    const { user, setAcceptTermsFormOpen } = this.props
    const self = this
    if (user && (typeof user.terms_accepted === 'boolean' && !user.terms_accepted)) {
      const cookie = this.getCookieByUser(lidarmillCookies.LIDARMILL_TERMS)
      if (cookie) {
        const expireDate = cookie.split('|')[1]
        const timeoutMs = new Date(expireDate).getTime() - new Date().getTime()
        if (this.timeout) {
          clearTimeout(this.timeout)
          this.timeout = setTimeout(() => self.checkAcceptedTerms(), timeoutMs)
        }
      } else {
        setAcceptTermsFormOpen(true)
      }
    }
  }

  checkAcceptedUpdate = () => {
    const { user } = this.props
    const self = this
    if (user) {
      const updatedAcceptedCookie = this.getCookie(lidarmillCookies.LIDARMILL_UPDATE_ACCEPTED)
      const updatedAcceptedStorage = localStorage.getItem(lidarmillCookies.LIDARMILL_UPDATE_ACCEPTED)
      if (!updatedAcceptedStorage && !updatedAcceptedCookie) {
        const updateCookie = this.getCookieByUser(lidarmillCookies.LIDARMILL_UPDATE)
        if (updateCookie) {
          const expireDate = updateCookie.split('|')[1]
          const timeoutMs = new Date(expireDate).getTime() - new Date().getTime()
          if (this.timeout) {
            clearTimeout(this.timeout)
            this.timeout = setTimeout(() => self.checkAcceptedUpdate(), timeoutMs)
          }
        } else {
          this.setState({ updateDialogOpen: true })
        }
      }
    }
  }

  getCookie = cookieName => {
    const cookies = document.cookie.split(';')
    return cookies.find(cookie => cookie.includes(`${cookieName}`))
  }

  getCookieByUser = cookieName => {
    const { userEmail } = this.props
    const cookies = document.cookie.split(';')
    return cookies.find(cookie => cookie.includes(`${cookieName}=${userEmail}`))
  }

  onAcceptTerms = () => {
    this.props.acceptTerms()
  }

  onAcceptUpdate = () => {
    document.cookie = `${lidarmillCookies.LIDARMILL_UPDATE_ACCEPTED}=16012021; max-age=${Infinity}`
    localStorage.setItem(lidarmillCookies.LIDARMILL_UPDATE_ACCEPTED, true)
    this.setState({ updateDialogOpen: false })
  }

  onCancelAcceptTerms = () => {
    this.onCancelDialog(lidarmillCookies.LIDARMILL_TERMS, this.props.setAcceptTermsFormOpen, this.checkAcceptedTerms)
  }

  onCancelUpdate = () => {
    this.onCancelDialog(lidarmillCookies.LIDARMILL_UPDATE, dialogOpen => this.setState({ updateDialogOpen: dialogOpen }), this.checkAcceptedUpdate)
  }

  onCancelDialog = (cookieName, setDialog, checkDialog) => {
    const { userEmail } = this.props
    const timeout = 120 * 60 * 1000 // 2 hours timeout
    const maxAge = 120 * 60 // 2 hours maxAge
    const expireDate = new Date(Date.now() + timeout)
    document.cookie = `${cookieName}=${userEmail}|${expireDate}; max-age=${maxAge}`
    this.timeout = setTimeout(() => checkDialog(), timeout)
    setDialog(false)
  }

  render () {
    const { location, isAcceptTermsDialogOpen } = this.props
    return (
      <React.Fragment>
        <AcceptUpdateDialog
          open={this.state.updateDialogOpen}
          onClose={this.onCancelUpdate}
          onSubmit={this.onAcceptUpdate}
        />
        <AcceptTermsDialog
          open={location.pathname.includes('terms') ? false : isAcceptTermsDialogOpen}
          onClose={this.onCancelAcceptTerms}
          onSubmit={this.onAcceptTerms}
        />
        <Check2FADialog/>
        <RegisterSystemDialog/>
        <Switch>
          <RedirectRoute exact from='/' to='/login' query={{}} />
          <RedirectRoute exact from='/myprofile' to='/login' query={{}} />
          <RedirectRoute exact from='/myupdates' to='/login' query={{}} />
          <RedirectRoute exact from='/subscriptions' to='/login' query={{}} />
          <Route exact path='/login' component={Login} />
          <Route exact path='/releases/changelogs/:product/:version' component={ProductReleases}/>
          <Route exact path='/releases/changelogs/:product' component={ProductReleases}/>
          <Route exact path='/releases/changelogs' component={ProductReleases}/>
          <Route exact path='/terms' component={Terms}/>
          <Route exact path='/recovery/:token' component={Recover} />
          <Route exact path='/redirect' component={Redirect} />
          <Route exact path='/external_redirect' component={ExternalRedirect} />
          <Route exact path='/email_confirmation/:token' component={EmailConfirmation} />
          <RegisterRoute exact path='/lostpassword' component={LostPassword} />
          <RegisterRoute exact path='/companies/register_success' component={CompanyUserRegisterSuccess} />
          <PrivateRoute exact path='/companies/:company_id/checkout_sessions/success' component={SuccessSession}/>
          <PrivateRoute exact path='/subscriptions/:subscription_id/checkout_sessions/success' component={SuccessSession}/>
          <PrivateRoute exact path='/companies/:company_id/checkout_sessions/cancel' component={CancelSession}/>
          <PrivateRoute exact path='/subscriptions/:subscription_id/checkout_sessions/cancel' component={CancelSession}/>
          <RegisterRoute exact path='/companies/:company_id/:token' component={EmployeeRegister}/>
          <RegisterRoute exact path='/register_success/' component={RegisterSuccess} />
          <PrivateRoute exact path='/register/system' component={RegisterSystem} />
          <Route exact path='/register' component={Register} />
          {/* <Redirect from="/paypal*" to='/payments' query={{}} /> */}
          <PrivateRoute exact path='/dashboard' component={Dashboard} />
          <PrivateRoute exact path='/error' component={ErrorPage} />
          <PrivateRoute exact path='/users/:user_id/:tab/:rover_id/:event_id/:sub_event_id' component={User} />
          <PrivateRoute exact path='/users/:user_id/:tab/:rover_id/:event_id' component={User} />
          <PrivateRoute exact path='/users/:user_id/:tab/:rover_id' component={User} />
          <PrivateRoute exact path='/users/:user_id/:tab' component={User} />
          <PrivateRoute exact path='/users/:user_id' component={User} />
          <PrivateRoute exact path='/company/:company_id/:tab/:subscription_id/:subscription_tab/:component_id' component={Company}/>
          <PrivateRoute exact path='/company/:company_id/:tab/:subscription_id/:subscription_tab' component={Company}/>
          <PrivateRoute exact path='/company/:company_id/:tab/:subscription_id' component={Company}/>
          <PrivateRoute exact path='/company/:company_id/:tab' component={Company}/>
          <PrivateRoute exact path='/company/:company_id' component={Company}/>
          <PrivateRoute exact path='/subscription/:subscription_id/:subscription_tab/:component_id' component={Subscription}/>
          <PrivateRoute exact path='/subscription/:subscription_id' component={Subscription}/>
          <PrivateRoute path='/users' component={Users} />
          <AdminRoute path='/statistics/se_crashes' component={SpatialExplorerCrashes} />
          <AdminRoute path='/statistics' component={Statistics} />
          <PrivateRoute path='/rovers/:rover_id/updates' component={RoverUpdates} />
          <PrivateRoute path='/rovers/:rover_id/:event_id/:sub_event_id' component={Rovers} />
          <PrivateRoute path='/rovers/:rover_id/:event_id' component={Rovers} />
          <PrivateRoute path='/rovers/:rover_id' component={Rovers} />
          <PrivateRoute path='/rovers' component={Rovers} />
          <AdminOrPLSRoute path='/sales_products' component={SalesProducts} />
          <PrivateRoute path='/system_types/:system_type_id' component={SystemTypes} />
          <PrivateRoute path='/system_types' component={SystemTypes} />
          <AdminOrPLSRoute path='/technical_service_bulletins/:recall_id' component={Recalls} />
          <AdminOrPLSRoute path='/technical_service_bulletins' component={Recalls} />
          <AdminOrPLSRoute path='/logs' component={Logs} />
          <AdminOrPLSRoute path='/da_events' component={DAEvents} />
          <AdminOrPLSRoute path='/da_request_events' component={DARequestEvents} />
          <AdminOrPLSRoute path='/rmas' component={RMA} />
          <AdminOrPLSRoute path='/rents' component={Rents} />
          <AdminOrPLSRoute path='/notifications' component={Notifications} />
          <AdminOrPLSRoute path='/subscriptions/expired' component={ExpiredSubscriptions} />
          <PrivateRoute component={ErrorPage}/>
        </Switch>
      </React.Fragment>
    )
  }
}

App.propTypes = {
  checkoutSessionId: PropTypes.string,
  checkoutSessionState: PropTypes.number,
  checkoutSessionTimeout: PropTypes.number,
  userId: PropTypes.string,
  userEmail: PropTypes.string,
  companyId: PropTypes.string,
  user: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  isUserAdmin: PropTypes.bool,
  isUserAdminOrPls: PropTypes.bool,
  isUserCustomer: PropTypes.bool,
  isAcceptTermsDialogOpen: PropTypes.bool,
  users: PropTypes.array,
  getMe: PropTypes.func,
  getUserReleases: PropTypes.func,
  logout: PropTypes.func,
  onUrlChange: PropTypes.func,
  acceptTerms: PropTypes.func,
  getSalesProducts: PropTypes.func,
  getTags: PropTypes.func,
  getUsers: PropTypes.func,
  getULData: PropTypes.func,
  getConstants: PropTypes.func,
  getCompanies: PropTypes.func,
  getCompanyUsers: PropTypes.func,
  getCompanyRovers: PropTypes.func,
  getSubscriptions: PropTypes.func,
  getAllSystemTypes: PropTypes.func,
  setAcceptTermsFormOpen: PropTypes.func,
  setRedirectURL: PropTypes.func,
  getAllProjects: PropTypes.func,
  getAllRovers: PropTypes.func,
  getDAREventTypes: PropTypes.func,
  getRecallTypes: PropTypes.func,
  getDAEventIssueTypes: PropTypes.func,
  getCheckListSteps: PropTypes.func,
  getRMAStatuses: PropTypes.func,
  getSystemTypeStatuses: PropTypes.func,
  getProducts: PropTypes.func,
  getCustomerTypes: PropTypes.func,
  getCheckoutSession: PropTypes.func,
}

const mapStateToProps = state => {
  const loggedUser = getLoggedUser(state)
  const constantsLoaded = isConstantsLoaded(state)
  return {
    checkoutSessionId: getCheckoutSessionId(state),
    checkoutSessionState: getCheckoutSessionStatus(state),
    checkoutSessionTimeout: getCheckoutSessionTimeout(state),
    isUserAdmin: isAdmin(state),
    isLoggedIn: isLoggedIn(state),
    isUserAdminOrPls: isUserAdminOrPLSUser(loggedUser),
    isUserCustomer: isUserCompanyCustomer(loggedUser),
    users: getUsers(state),
    user: loggedUser,
    userId: loggedUser && loggedUser.id,
    userEmail: loggedUser && loggedUser.email,
    companyId: getCompanyId(loggedUser),
    isAcceptTermsDialogOpen: isAcceptTermsFormOpen(state),
    constantsLoaded,
  }
}

const mapDispatchToProps = dispatch => ({
  getCheckoutSession: (sessionId, sessionState) => dispatch(BillingAgreementActions.getCheckoutSession(sessionId, sessionState)),
  getSubscriptions: (companyId, withLoading) => dispatch(CompaniesActions.getCompanySubscriptions(companyId, withLoading)),
  getUsers: () => dispatch(UsersActions.getUsers(['has_ongoing_subscription'])),
  getCompanies: () => dispatch(CompaniesActions.getCompanies()),
  getCompanyUsers: companyId => dispatch(CompaniesActions.getCompanyUsers(companyId)),
  getCompanyRovers: companyId => dispatch(CompaniesActions.getCompanyRovers(companyId)),
  onUrlChange: url => dispatch(UrlActions.changeUrl(url)),
  getMe: () => dispatch(UsersActions.getMe()),
  getUserReleases: () => dispatch(UsersActions.getUserReleases()),
  logout: () => dispatch(UsersActions.logout()),
  setAcceptTermsFormOpen: open => dispatch(UsersActions.setAcceptTermsFormOpen(open)),
  setRedirectURL: url => dispatch(UrlActions.setRedirectURL(url)),
  acceptTerms: () => dispatch(UsersActions.acceptTerms()),
  getAllSystemTypes: () => dispatch(AppActions.getAllSystemTypes()),
  getSalesProducts: () => dispatch(BillingAgreementActions.getSalesProducts()),
  getConstants: () => dispatch(AppActions.getConstants()),
  getTags: () => dispatch(AppActions.getTags()),
  getULData: () => dispatch(AppActions.getULData()),
  getAllProjects: () => dispatch(AppActions.getAllProjects()),
  getDAREventTypes: () => dispatch(AppActions.getDAREventTypes()),
  getRecallTypes: () => dispatch(AppActions.getRecallTypes()),
  getDAEventIssueTypes: () => dispatch(AppActions.getDAEventIssueTypes()),
  getCheckListSteps: () => dispatch(AppActions.getCheckListSteps()),
  getRMAStatuses: () => dispatch(AppActions.getRMAStatuses()),
  getSystemTypeStatuses: () => dispatch(AppActions.getSystemTypeStatuses()),
  getAllRovers: () => dispatch(RoversActions.getAllRovers()),
  getProducts: () => dispatch(AppActions.getProducts()),
  getCustomerTypes: () => dispatch(AppActions.getAllCustomerTypes()),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    withRoot(withNamespaces('common')(App)),
  ),
)
