import config from 'config'
import { getUTCDate } from 'utils/dateTime'
import { convertRawAttachment } from 'types/attachments'
import { LicenseKeyType } from './licenseKeys'

export const EventType = {
  UNLEASHED: 'unleashed',
  LIDARMILL: 'lidarmill',
  MANUAL: 'manual',
  PREDEFINED: 'predefined',
  NONE: 'none',
}

export const RoverTypes = {
  RECON: 'RECON',
  PLS: 'Phoenix LiDAR Systems',
}

export const RoverEventType = {
  RMA: 'rma',
  SALE: 'sale',
  CUSTOMER_SUPPORT: 'customer_support',
  CALIBRATION: 'calibration',
  DATA_ACQUISITION: 'data_acquisition',
  DATA_ACQUISITION_REQUEST: 'data_acquisition_request',
  CUSTOM_SETTING: 'custom-setting',
  COMPONENTINFO: 'componentinfo',
  SYSTEM_TYPE: 'system_type',
  SHIPPING: 'shipping',
  REGISTER: 'register',
  RENT: 'rent',
  SERIAL: 'serial',
  ROVER: 'rover',
  RECALL: 'recall',
  RECALL_EVENT: 'recall_event',
  MISSION: 'mission',
  DECOMMISSIONED: 'decommissioned',
  REVERSE_DECOMMISSIONED: 'reverse_decommissioned',
  SYSTEM_MAINTENANCE: 'system_maintenance',
  ROVER_SYSTEM_TYPE: 'rovers_system_types',
  ROVER_SYSTEM_TYPE_PROFILE: 'profiles',
  ROVER_SYSTEM_TYPE_CONFIGURATION: 'configurations',
  BUILD: 'build',
  HISTORY_RESET: 'history_reset',
}

export const PrettyRoverEventType = {
  [RoverEventType.RMA]: 'RMA',
  [RoverEventType.SALE]: 'Sale',
  [RoverEventType.CUSTOMER_SUPPORT]: 'Support',
  [RoverEventType.CALIBRATION]: 'Calibration',
  [RoverEventType.DATA_ACQUISITION]: 'Data Acquisition',
  [RoverEventType.DATA_ACQUISITION_REQUEST]: 'Data Acquisition request',
  [RoverEventType.CUSTOM_SETTING]: 'Custom',
  [RoverEventType.COMPONENTINFO]: 'Componentinfo',
  [RoverEventType.SYSTEM_TYPE]: 'SystemType',
  [RoverEventType.SHIPPING]: 'Shipping',
  [RoverEventType.REGISTER]: 'Register',
  [RoverEventType.RENT]: 'Rent',
  [RoverEventType.SERIAL]: 'Serial',
  [RoverEventType.Rover]: 'Rover',
  [RoverEventType.RECALL]: 'Parent TSB',
  [RoverEventType.RECALL_EVENT]: 'Technical Service Bulletins',
  [RoverEventType.MISSION]: 'Mission',
  [RoverEventType.DECOMMISSIONED]: 'Decommission',
  [RoverEventType.REVERSE_DECOMMISSIONED]: 'Reverse decommission',
  [RoverEventType.SYSTEM_MAINTENANCE]: 'System Maintenance',
  [RoverEventType.BUILD]: 'Build',
  [RoverEventType.HISTORY_RESET]: 'History reset',
}

export const RoverEvents = [
  RoverEventType.RMA,
  RoverEventType.SALE,
  RoverEventType.CUSTOMER_SUPPORT,
  RoverEventType.CALIBRATION,
  RoverEventType.DATA_ACQUISITION,
  RoverEventType.CUSTOM_SETTING,
  RoverEventType.COMPONENTINFO,
  RoverEventType.SYSTEM_TYPE,
  RoverEventType.SHIPPING,
  RoverEventType.REGISTER,
  RoverEventType.RENT,
  RoverEventType.SERIAL,
  RoverEventType.RECALL_EVENT,
  RoverEventType.MISSION,
  RoverEventType.DECOMMISSIONED,
  RoverEventType.REVERSE_DECOMMISSIONED,
  RoverEventType.DATA_ACQUISITION_REQUEST,
  RoverEventType.SYSTEM_MAINTENANCE,
  RoverEventType.BUILD,
  RoverEventType.HISTORY_RESET,
].map(eventType => ({
  id: eventType,
  label: PrettyRoverEventType[eventType],
}))

export const UserRoverEvents = [
  // RoverEventType.SALE,
  RoverEventType.SYSTEM_TYPE,
  RoverEventType.DECOMMISSIONED,
  RoverEventType.RECALL_EVENT,
  RoverEventType.CALIBRATION,
  RoverEventType.RMA,
  // RoverEventType.HISTORY_RESET,
  // RoverEventType.CUSTOM_SETTING,
  // RoverEventType.REGISTER,
  // RoverEventType.RENT,
  // RoverEventType.MISSION,
].map(eventType => ({
  id: eventType,
  label: PrettyRoverEventType[eventType],
}))

export const RoverEventTypeColor = {
  [RoverEventType.RMA]: 'rgb(235, 120, 118, 0.5)',
  [RoverEventType.SALE]: 'rgba(136, 211, 136, 0.5)',
  [RoverEventType.CUSTOMER_SUPPORT]: 'rgba(101, 196, 246, 0.5)',
  [RoverEventType.CALIBRATION]: 'rgb(255, 235, 59, 0.5)',
  [RoverEventType.DATA_ACQUISITION]: '#ffbd45',
  [RoverEventType.DATA_ACQUISITION_REQUEST]: '#ffe1ab',
  [RoverEventType.CUSTOM_SETTING]: 'rgba(149, 142, 238, 0.5)',
  [RoverEventType.COMPONENTINFO]: 'rgb(213, 157, 83, 0.5)',
  [RoverEventType.SYSTEM_TYPE]: 'rgba(121, 85, 72, 0.5)',
  [RoverEventType.SHIPPING]: 'rgba(209, 133, 195, 0.5)',
  [RoverEventType.REGISTER]: 'rgb(99, 121, 72, 0.5)',
  [RoverEventType.RENT]: 'rgb(0, 150, 136, 0.5)',
  [RoverEventType.SERIAL]: 'rgba(233, 30, 99, 0.5)',
  [RoverEventType.RECALL_EVENT]: 'rgba(233, 30, 99, 0.25)',
  [RoverEventType.MISSION]: 'rgb(198, 213, 246, 0.5)',
  [RoverEventType.DECOMMISSIONED]: '#a9a9a9',
  [RoverEventType.REVERSE_DECOMMISSIONED]: '#a9a9a9',
  [RoverEventType.SYSTEM_MAINTENANCE]: 'rgba(101, 100, 246, 0.5)',
  [RoverEventType.BUILD]: 'rgb(0, 65, 86, 0.35)',
  [RoverEventType.HISTORY_RESET]: '#e3e2e2',
}

export const DocumentType = {
  SHIPPING_PHOTO: 'shipping_photo',
  WORK_REPORT: 'work_report',
  DIAGNOSTIC_REPORT: 'diagnostic_report',
  CALIBRATION: 'calibration',
  CALIBRATION_REPORT: 'calibration_report',
  CALIBRATION_CERTIFICATE: 'calibration_certificate',
  THUMBNAIL: 'thumbnail',
  DRAWING: 'drawing',
  PHOTOS: 'photos',
  STATEMENT_OF_WORK: 'statement_of_work',
  FINAL_AOI: 'final_aoi',
  LABELING_PHOTO: 'labeling_photo',
}
export const PrettyDocumentType = {
  [DocumentType.SHIPPING_PHOTO]: 'Shipping photo',
  [DocumentType.WORK_REPORT]: 'Work report',
  [DocumentType.DIAGNOSTIC_REPORT]: 'Diagnostic report',
  [DocumentType.CALIBRATION]: 'Calibration',
  [DocumentType.CALIBRATION_REPORT]: 'Calibration report',
  [DocumentType.CALIBRATION_CERTIFICATE]: 'Calibration certificate',
  [DocumentType.PHOTOS]: 'Photos',
  [DocumentType.DRAWING]: 'Drawings',
  [DocumentType.STATEMENT_OF_WORK]: 'Statement of work',
  [DocumentType.FINAL_AOI]: 'Final Area of Interest',
  [DocumentType.LABELING_PHOTO]: 'Labeling Photo',
  'null': 'Other documents',
}
export const RecallTypes = {
  PRIVATE: 'private',
  PUBLIC: 'public',
}

export const PrettyRecallTypes = {
  [RecallTypes.PRIVATE]: 'Private',
  [RecallTypes.PUBLIC]: 'Public',
}

export const RecallStatuses = {
  PUBLISHED: 'published',
  PENDING_SIGN_OFF: 'pending_sign_off',
}

export const PrettyRecallStatuses = {
  [RecallStatuses.PUBLISHED]: 'Published',
  [RecallStatuses.PENDING_SIGN_OFF]: 'Pending SignOff',
}
/**
 * Generic type definition for an option used in select/combobox
 * @typedef {Object} Option
 * @property {String} label
 * @property {String} value
 */
/**
 * All available document type options for the rover event attachment
 * @type {Array.<Option>}
 */
const AllDocumentTypes = [
  { label: PrettyDocumentType[DocumentType.SHIPPING_PHOTO], value: DocumentType.SHIPPING_PHOTO },
  { label: PrettyDocumentType[DocumentType.WORK_REPORT], value: DocumentType.WORK_REPORT },
  { label: PrettyDocumentType[DocumentType.DIAGNOSTIC_REPORT], value: DocumentType.DIAGNOSTIC_REPORT },
  { label: PrettyDocumentType[DocumentType.CALIBRATION_REPORT], value: DocumentType.CALIBRATION_REPORT },
  { label: PrettyDocumentType[DocumentType.LABELING_PHOTO], value: DocumentType.LABELING_PHOTO },
]
/**
 * Document types specific for the rover event
 */
export const DocumentTypesByRoverEvent = {
  [RoverEventType.RMA]: AllDocumentTypes.filter(docType => (
    docType.value === DocumentType.WORK_REPORT ||
    docType.value === DocumentType.DIAGNOSTIC_REPORT
  )),
  [RoverEventType.SHIPPING]: AllDocumentTypes.filter(docType => (
    docType.value === DocumentType.SHIPPING_PHOTO ||
    docType.value === DocumentType.LABELING_PHOTO
  )),
  'licenseKey': [
    { label: 'Node locked', value: LicenseKeyType.NODE_LOCKED, id: LicenseKeyType.NODE_LOCKED },
    { label: 'Network', value: LicenseKeyType.NETWORK, id: LicenseKeyType.NETWORK },
  ],
}
/**
 * These document types are available as fallback when rover event type is not provided
 * @type {Array.<Option>}
 */
export const DocumentTypes = AllDocumentTypes.filter(({ value }) => value !== DocumentType.LABELING_PHOTO)
/**
 * Returns an array of document types options for the rover event type
 * @param {String} eventType
 * @returns {Array.<Option>}
 */
export function getDocumentTypesForRoverEvent (eventType) {
  return DocumentTypesByRoverEvent[eventType] || DocumentTypes
}

export const convertRawRoverEvent = (raw, eventType) => {
  const created = raw.created_at || raw.created
  const updated = raw.updated_at || raw.updated
  const timestamp = eventType === RoverEventType.MISSION
    ? raw.day_of_flight || created
    : raw.timestamp || created
  const statuses = raw.statuses
  const attachments = raw.attachments || []
  const data_acquisition_events = raw.data_acquisition_events || []
  let dateSort = timestamp
  // For rent events we should use created date instead of timestamp
  if (eventType === RoverEventType.RENT) {
    dateSort = created
  }
  /**
   * Match datatime with componentinfo
   * @note could be wrong and we should use 'created'
   */
  if (eventType === RoverEventType.COMPONENTINFO) {
    dateSort = updated
  }
  return {
    ...raw,
    type: eventType,
    id: raw.slug || raw.id,
    // ...(raw.rma && { rma: { id: raw.rma.rma_number } }),
    // ...(raw.children && { children: raw.children.map(child => ({ id: child.rma_number })) }),
    created: created && getUTCDate(created).format(config.DATETIME_FORMAT),
    updated: updated && getUTCDate(updated).format(config.DATETIME_FORMAT),
    timestamp: timestamp && getUTCDate(timestamp).format(config.DATETIME_FORMAT),
    system: (raw.system || '').toString(),
    data_acquisition_events: data_acquisition_events.map(daEvent => daEvent.id),
    attachments: attachments.map(convertRawAttachment),
    dateSort: dateSort && getUTCDate(dateSort).format(config.DATETIME_FORMAT),
    ...('statuses' in raw && { status: statuses[0] }),
  }
}

export const StepState = {
  DONE: 'done',
  NOT_DONE: 'not_done',
  DOES_NOT_APPLY: 'does_not_apply',
}

export const StepStateOptions = [
  { label: 'Not done', value: StepState.NOT_DONE },
  { label: 'Done', value: StepState.DONE },
  { label: 'Does not apply', value: StepState.DOES_NOT_APPLY },
]

export const DefaultStepState = StepState.NOT_DONE
