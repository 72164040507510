// External dependencies.
import React from 'react'
import { path, equals } from 'ramda'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import { withRouter, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
// Material UI dependencies.
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import Toolbar from '@material-ui/core/Toolbar'
import Collapse from '@material-ui/core/Collapse'
import Hidden from '@material-ui/core/Hidden'
// import Tooltip from '@material-ui/core/Tooltip'
// import IconButton from '@material-ui/core/IconButton'
// import Badge from '@material-ui/core/Badge'
// Icons
import SubCompaniesIcon from '@material-ui/icons/Business'
import LoginIcon from '@material-ui/icons/Input'
import TermsIcon from '@material-ui/icons/CopyrightOutlined'
import AccountIcon from '@material-ui/icons/Person'
import FaceIcon from '@material-ui/icons/FaceOutlined'
import PeopleIcon from '@material-ui/icons/PeopleOutlined'
import PaymentIcon from '@material-ui/icons/PaymentOutlined'
import BarChartIcon from '@material-ui/icons/BarChartOutlined'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import PlanIcon from '@material-ui/icons/EventNote'
// import ReleasesIcon from '@material-ui/icons/Message'
import RoverIcon from '@material-ui/icons/Flight'
import SystemTypeIcon from '@material-ui/icons/Settings'
import LogIcon from '@material-ui/icons/Assignment'
import RecallIcon from '@material-ui/icons/ChatBubble'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import ShoppingCart from '@material-ui/icons/ShoppingCart'
// Project internal dependencies.
import UsersActions from 'modules/users/actions'
// import RoversActions from 'modules/rovers/actions'
import { getLoggedUser, isCurrentUserCompanyEmployee, isCurrentUserCompanyCustomer, getActiveUser, getTotalReleases, getReleases } from 'modules/users/selectors'
import { getTabValue } from 'templates/tabs'
import { getBasicRouteUrl, Route as RouteName } from 'templates/route'
import { routeUser, routeCompany, routeCompanySubscriptions, routeCompanySubscriptionsShop, routeDashboard, routeCompanyRovers, routeCompanyUsers, routeCompanySubCompanies } from 'utils/routing'
import { getCompanyId, isUserAdmin, isUserAdminOrPLSUser, isUserCompanyCustomer } from 'utils/company'
import Breadcrumbs from 'components/Layout/Breadcrumbs'
import Search from 'components/Search'
import PLSLogo from 'assets/images/PLS_logo.svg'
import LidarMillLogo from 'assets/images/Lidarmill_logo.ico'
// Local deps
import Navigation from './Navigation'
import SubscriptionStatusAndQuota from './SubscriptionStatusAndQuota'
import { getRedirectUrl } from 'modules/url/selectors'
import StyledTooltip from 'components/reusable/StyledTooltip'
import ShowUserLogsDialog from 'components/dialogs/UserLogsDialog'
import ShowCompanyLogsDialog from 'components/dialogs/CompanyLogsDialog'
import ProductReleasesDialog from 'components/dialogs/ProductReleasesDialog'
import { getChildCompanies, getCompanyRovers } from 'modules/companies/selectors'
import { removeLidarmillCookies } from 'modules/users/sagas'
import AdminOnlyComponent from 'components/reusable/AdminOnlyComponent'

const styles = theme => ({
  appBar: {
    // maxHeight: 150
    backgroundColor: theme.palette.header,
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(0),
    },
    zIndex: '1102',
  },
  secondaryBar: {
    zIndex: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    backgroundColor: theme.palette.header,
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
    // overflowX: 'auto'
  },
  action: {
    color: '#fff',
    boxShadow: theme.mixins.boxShadow,
    backgroundColor: '#32373d !important',
    minHeight: 40,
    borderRadius: 4,
    '&&:hover': {
      backgroundColor: '#3b4148 !important',
    },
    [theme.breakpoints.down('md')]: {
      width: '100% !important',
    },
  },
  actionButton: {
    width: 270,
  },
  me: {
    padding: theme.spacing(0, 1),
    borderColor: '#fff', // theme.palette.primary.light,
    border: 'none !important',
    // backgroundColor: '#e0e0e0', // theme.palette.primary.dark,
    height: '100%',
    width: 270,
  },
  meLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  adminLink: {
    color: theme.palette.secondary.main,
  },
  tabsRoot: {
    fontWeight: '500',
  },
  tabsSelected: {
    background: 'rgba(0,0,0,0) !important',
  },
  subscriptionItem: {
    display: 'flex',
    width: '100%',
  },
  headerContainer: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  logoContainer: {
    padding: theme.spacing(0),
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      // flexWrap: 'wrap',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1),
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  },
  logoLink: {
    width: 180,
    height: 90,
    display: 'block',
    [theme.breakpoints.down('md')]: {
      minWidth: 120,
      width: 120,
      minHeight: 60,
      height: 60,
      marginRight: theme.spacing(2),
    },
  },
  controls: {
    margin: 0,
    padding: 0,
    maxWidth: 'max-content',
    marginLeft: 'auto',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  box: {
    width: 500,
    alignItems: 'center',
    paddingTop: 0,
    marginTop: -4,
    '@media (min-width: 1080px)': {
      flexWrap: 'noWrap',
    },
  },
  boxAlign: {
    justifyContent: 'flex-end',
  },
  loginBox: {
    display: 'flex',
    flexWrap: 'noWrap',
    '@media (max-width: 1080px)': {
      flexWrap: 'wrap',
    },
  },
  adminControl: {
    paddingLeft: 46,
  },
  controlBox: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    '@media (min-width: 1080px)': {
      marginLeft: 0,
      flexWrap: 'noWrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: 'fit-content',
    },
  },
})

class Header extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      accountMenuOpen: false,
      subscriptionMenuOpen: true,
      eventsMenuOpen: true,
      statisticsMenuOpen: true,
      notificationDialogOpen: false,
      tabsValue: 0,
      tabsAmount: 0,
      anchorEl: null,
      width: 0,
      projectTabsWidth: null,
      basicUrl: this.getBasicUrl(props),
      dialogOpened: '',
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (!equals(this.props, nextProps) || !equals(this.state, nextState)) {
      return true
    }
    return false
  }

  componentDidMount () {
    this.updateTabValue(null, this.props)
  }

  componentDidUpdate (prevProps) {
    this.updateTabValue(prevProps, this.props)
  }

  onChangeProjectTabs = (width, height) => this.setState({ projectTabsWidth: width })

  onCloseDialog = e => {
    this.setState({ dialogOpened: '' })
  }

  toggleAccountMenu = open => e => {
    this.setState({
      accountMenuOpen: open,
      anchorEl: open ? e.currentTarget : null,
    })
  }

  toggleSubscriptionMenu = e => {
    this.setState(prevState => ({
      subscriptionMenuOpen: !prevState.subscriptionMenuOpen,
    }))
  }

  toggleSubMenu = name => e => {
    this.setState(prevState => ({
      [name]: !prevState[name],
    }))
  }
  toggleOpenNotificationDialog = () => {
    this.setState(prevState => ({
      notificationDialogOpen: !prevState.notificationDialogOpen,
    }))
  }

  getBasicUrl = props => {
    const { match } = props
    const url = match.url
    return url.split('/').filter(x => x)[0]
  }

  updateTabValue = (prevProps, props) => {
    const { tabsValue: stateTabsValue } = this.state
    const { match, tab: propsTab } = props
    // check the url to set the tab index value
    // due to reloading glitch
    const tab = match.params.tab || propsTab
    if (path(['match', 'params', 'tab'], prevProps) !== tab || tab) {
      const basicUrl = this.getBasicUrl(props)
      const tabsValue = getTabValue(match, basicUrl, tab, props)
      if (stateTabsValue !== tabsValue) this.setState({ tabsValue, basicUrl })
    }
  }

  onTabsChange = (e, tabsValue) => {
    if (typeof tabsValue !== 'boolean') {
      e.preventDefault()
      this.setState({ tabsValue })
      this.props.history.push(e.currentTarget.id)
    }
  }

  handleBreadcrumbClick = tabsValue => e => this.onTabsChange(e, tabsValue)
  handleMenuItemClick = routeName => e => this.props.history.push(getBasicRouteUrl(routeName))
  handleCompanyClick = () => this.props.history.push(routeCompany(path(['company', 'id'], this.props.user)))

  render () {
    const { dialogOpened, tabsAmount } = this.state
    const {
      classes,
      breadcrumbs,
      match,
      // userId,
      activeUserId,
      activeCompanyId,
      forwardRef,
      onLoginAs,
      loginAs,
      loggedUser,
      activeUser,
      logs,
      // totalReleases,
    } = this.props

    const additionalBoxStyles = tabsAmount === 0 ? classes.boxAlign : ''

    return (
      <AppBar ref={forwardRef} color='primary' position='sticky' elevation={0} classes={{ root: classes.appBar }}>
        {dialogOpened === 'user' && <ShowUserLogsDialog
          open
          onClose={this.onCloseDialog}
          userId={activeUserId}
          showRelated
        />
        }
        {dialogOpened === 'company' && <ShowCompanyLogsDialog
          open
          onClose={this.onCloseDialog}
          companyId={activeCompanyId}
          showRelated
        />
        }
        <ProductReleasesDialog
          open={this.state.notificationDialogOpen}
          onClose={this.toggleOpenNotificationDialog}
        />
        <Grid key='header' className={classes.headerContainer}>
          <Grid className={classes.logoContainer}>
            <Link to={routeDashboard()} className={classes.logoLink}>
              <img src={PLSLogo} style={{ width: '100%', height: '100%' }} />
            </Link>
            <Hidden only={['lg', 'xl']}>
              <Breadcrumbs
                onClick={this.handleBreadcrumbClick}
                breadcrumbsData={breadcrumbs}
                match={match}
                extraProps={this.props}
              />
            </Hidden>
          </Grid>
          <Grid style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Hidden mdDown>
              <Toolbar style={{ padding: '0px 0px 0px 4px' }}>
                <Grid container style={{ flexWrap: 'nowrap', padding: 0 }}>
                  <Grid item style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                    <Breadcrumbs
                      onClick={this.handleBreadcrumbClick}
                      breadcrumbsData={breadcrumbs}
                      match={match}
                      extraProps={this.props}
                    />
                  </Grid>
                  <Grid item style={{ display: 'flex', marginLeft: 'auto', alignItems: 'center' }}>
                    <div style={{ padding: 4 }}>
                      {loginAs && <Button
                        startIcon={<LoginIcon />}
                        fullWidth
                        onClick={onLoginAs(activeUser.id, loggedUser.email)}
                        variant='contained'
                        className={classNames(classes.action, classes.actionButton)}
                      >
                        <span style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}>Login as {activeUser.email}</span>
                      </Button>
                      }
                    </div>
                  </Grid>
                </Grid>
              </Toolbar>
            </Hidden>
            <div style={{ flexGrow: 1, width: '100%' }}>
              <AppBar
                className={classes.secondaryBar}
                color='primary'
                position='static'
                elevation={0}>
                <Grid item container xs={12} justifyContent='space-between' className={classNames(classes.box, additionalBoxStyles)}>
                  <Hidden only={['md', 'sm', 'xs']}>
                    <Navigation
                      {...this.state}
                      {...this.props}
                      onTabsChange={this.onTabsChange}
                      onResize={this.onChangeProjectTabs}
                      setTabsAmount={length => this.setState({ tabsAmount: length })}
                    />
                  </Hidden>
                  <Grid container spacing={1} className={classes.controlBox}>
                    <Grid item container xs={12} justifyContent="space-between" className={classes.loginBox}>
                      {loginAs && <Hidden only={['lg', 'xl']}>
                        <Grid item xs={12} sm={12}>
                          <Button
                            startIcon={<LoginIcon />}
                            fullWidth
                            onClick={onLoginAs(activeUser.id, loggedUser.email)}
                            variant='contained'
                            className={classes.action}
                          >
                            <span style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}>Login as {activeUser.email}</span>
                          </Button>
                        </Grid>
                      </Hidden>
                      }
                    </Grid>
                    {<AdminOnlyComponent>
                      <React.Fragment>
                        <Hidden only={['lg', 'xl']}><Grid item xs={12}><Search /></Grid></Hidden>
                        <Hidden mdDown><Grid item><Search /></Grid></Hidden>
                      </React.Fragment>
                    </AdminOnlyComponent>
                    }
                    {logs &&
                      <AdminOnlyComponent>
                        <React.Fragment>
                          <Hidden only={['lg', 'xl']}>
                            <Grid item xs={2}>
                              <StyledTooltip title={logs === 'user' ? 'Show user logs' : 'Show company logs'}>
                                <Button onClick={() => this.setState({ dialogOpened: logs })} fullWidth className={classes.action} style={{ width: '100%', minWidth: 0 }}>
                                  <LogIcon />
                                </Button>
                              </StyledTooltip>
                            </Grid>
                          </Hidden>
                          <Hidden mdDown>
                            <Grid item>
                              <StyledTooltip title={logs === 'user' ? 'Show user logs' : 'Show company logs'}>
                                <Button onClick={() => this.setState({ dialogOpened: logs })} className={classes.action} style={{ width: 50, minWidth: 0 }}>
                                  <LogIcon />
                                </Button>
                              </StyledTooltip>
                            </Grid>
                          </Hidden>
                        </React.Fragment>
                      </AdminOnlyComponent>
                    }
                    <React.Fragment>
                      <Hidden only={['lg', 'xl']}><Grid item xs={logs ? 10 : 12}>{renderMenu.bind(this, 'dashboard')()}</Grid></Hidden>
                      <Hidden mdDown><Grid item>{renderMenu.bind(this, 'dashboard')()}</Grid></Hidden>
                    </React.Fragment>
                  </Grid>
                </Grid>
                <Hidden lgUp>
                  <Navigation
                    {...this.state}
                    {...this.props}
                    onTabsChange={this.onTabsChange}
                    onResize={this.onChangeProjectTabs}
                    setTabsAmount={length => this.setState({ tabsAmount: length })}
                  />
                </Hidden>
              </AppBar>
            </div>
          </Grid>
        </Grid>
      </AppBar>
    )
  }
}

function renderMenu () {
  const { anchorEl, eventsMenuOpen, statisticsMenuOpen } = this.state
  const {
    onLogout,
    user,
    isAdmin,
    isAdminOrPLSUser,
    isUserCustomer,
    classes,
    userId,
    activeCompanyId: companyId,
    redirectURL,
    loggedAs,
    loggedUserCompanyChildCompanies,
    // openRegisterSystemDialog,
  } = this.props
  const userEmail = user.email
  return (
    <React.Fragment>
      <Button
        aria-controls='user-menu'
        aria-haspopup='true'
        variant='outlined'
        className={classNames(classes.action, classes.me)}
        onClick={this.toggleAccountMenu(true)}
        startIcon={<AccountIcon style={{ color: loggedAs ? 'red' : 'inherit' }}/>}
      >
        <Hidden xsDown>
          <span style={{ color: loggedAs ? 'red' : 'inherit' }} className={classes.meLabel}>
            {loggedAs ? `Be aware. Logged as ${userEmail}. ` : userEmail}
          </span>
        </Hidden>
        {anchorEl ? <ExpandLessIcon/> : <ExpandMoreIcon /> }
      </Button>
      <Menu
        id='user-menu'
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        anchorOrigin={{
          vertical: 42,
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        onClose={this.toggleAccountMenu(false)}>
        <Hidden only={['sm', 'md', 'lg', 'xl']}>
          <MenuItem>
            <ListItemText style={{ color: loggedAs ? 'red' : 'inherit' }}>
              {loggedAs ? `Be aware. Logged as ${userEmail}.` : userEmail}
            </ListItemText>
          </MenuItem>
        </Hidden>
        <SubscriptionStatusAndQuota userId={userId} companyId={companyId}/>
        { redirectURL &&
          <MenuItem component='a' href={redirectURL}>
            <ListItemIcon><img src={LidarMillLogo} width={20} height={20}/></ListItemIcon>
            <ListItemText>Back to LiDARMill</ListItemText>
          </MenuItem>
        }
        <MenuItem component={Link} to={routeUser(this.props.user.id)}>
          <ListItemIcon><FaceIcon /></ListItemIcon>
          <ListItemText><Trans i18nKey='app.menu.userMenu.profile' /></ListItemText>
        </MenuItem>
        { !isUserCustomer &&
          <MenuItem
            component={Link}
            to={routeCompanySubscriptions(getCompanyId(this.props.user))}>
            <ListItemIcon><PaymentIcon /></ListItemIcon>
            <ListItemText><Trans i18nKey='app.menu.userMenu.transactions' /></ListItemText>
          </MenuItem>
        }
        { !isUserCustomer &&
          <MenuItem
            component={Link}
            to={routeCompanySubscriptionsShop(getCompanyId(this.props.user))}>
            <ListItemIcon><ShoppingCart /></ListItemIcon>
            <ListItemText><Trans i18nKey='app.menu.userMenu.shop' /></ListItemText>
          </MenuItem>
        }
        <AdminOnlyComponent>
          <MenuItem
            component={Link}
            to='/sales_products'>
            <ListItemIcon><PlanIcon /></ListItemIcon>
            <ListItemText>Plans</ListItemText>
          </MenuItem>
        </AdminOnlyComponent>
        <MenuItem
          component={Link}
          to={isAdminOrPLSUser ? getBasicRouteUrl(RouteName.USERS) : routeCompanyUsers(getCompanyId(this.props.user))}>
          <ListItemIcon><PeopleIcon /></ListItemIcon>
          <ListItemText><Trans i18nKey='app.menu.userMenu.users' /></ListItemText>
        </MenuItem>
        {loggedUserCompanyChildCompanies && loggedUserCompanyChildCompanies.length > 0 && <MenuItem
          component={Link}
          to={routeCompanySubCompanies(getCompanyId(this.props.user))}>
          <ListItemIcon><SubCompaniesIcon /></ListItemIcon>
          <ListItemText><Trans i18nKey='app.menu.userMenu.sub_companies' /></ListItemText>
        </MenuItem>
        }
        {/* <MenuItem
          component={Link}
          to={getBasicRouteUrl(RouteName.REGISTER_SYSTEM)}>
          <ListItemIcon><RoverIcon /></ListItemIcon>
          <ListItemText>Register your system</ListItemText>
        </MenuItem>
        */}
        <MenuItem
          component={Link}
          to={isAdminOrPLSUser ? getBasicRouteUrl(RouteName.ROVERS) : routeCompanyRovers(getCompanyId(this.props.user))}>
          <ListItemIcon><RoverIcon /></ListItemIcon>
          <ListItemText>Rovers</ListItemText>
        </MenuItem>
        <MenuItem
          component={Link}
          to={getBasicRouteUrl(RouteName.SYSTEM_TYPES)}>
          <ListItemIcon><SystemTypeIcon /></ListItemIcon>
          <ListItemText>System types</ListItemText>
        </MenuItem>
        <AdminOnlyComponent>
          <MenuItem
            component={Link}
            to={getBasicRouteUrl(RouteName.LOGS)}>
            <ListItemIcon><LogIcon /></ListItemIcon>
            <ListItemText>Logs</ListItemText>
          </MenuItem>
        </AdminOnlyComponent>
        <AdminOnlyComponent>
          <MenuItem
            component={Link}
            to={getBasicRouteUrl(RouteName.NOTIFICATIONS)}>
            <ListItemIcon><LogIcon /></ListItemIcon>
            <ListItemText>Notifications</ListItemText>
          </MenuItem>
        </AdminOnlyComponent>
        <AdminOnlyComponent>
          <MenuItem
            key='menu-open'
            onClick={this.toggleSubMenu('eventsMenuOpen')}>
            <ListItemIcon><RecallIcon /></ListItemIcon>
            <ListItemText classes={{ primary: classes.subscriptionItem }}>
              Events
              {eventsMenuOpen
                ? <ExpandLessIcon style={{ marginLeft: 'auto' }}/>
                : <ExpandMoreIcon style={{ marginLeft: 'auto' }}/>
              }
            </ListItemText>
          </MenuItem>
          <Collapse in={eventsMenuOpen} key='sub-menu'>
            <div style={{ borderBottom: '1px solid #efefef' }}>
              <MenuItem
                className={classes.adminControl}
                component={Link}
                to={getBasicRouteUrl(RouteName.ASSEMBLIES)}>
                <ListItemText>Assemblies</ListItemText>
              </MenuItem>
              <MenuItem
                className={classes.adminControl}
                component={Link}
                to={getBasicRouteUrl(RouteName.RMAS)}>
                <ListItemText>RMAs</ListItemText>
              </MenuItem>
              <MenuItem
                className={classes.adminControl}
                component={Link}
                to={getBasicRouteUrl(RouteName.RENTS)}>
                <ListItemText>Rents</ListItemText>
              </MenuItem>
              <MenuItem
                className={classes.adminControl}
                component={Link}
                to={getBasicRouteUrl(RouteName.RECALLS)}>
                <ListItemText>Technical Service Bulletins</ListItemText>
              </MenuItem>
              <MenuItem
                className={classes.adminControl}
                component={Link}
                to={getBasicRouteUrl(RouteName.DA_EVENTS)}>
                <ListItemText>Data Acquisition</ListItemText>
              </MenuItem>
              <MenuItem
                className={classes.adminControl}
                component={Link}
                to={getBasicRouteUrl(RouteName.DA_REQUEST_EVENTS)}>
                <ListItemText>Data Acquisition Request</ListItemText>
              </MenuItem>
            </div>
          </Collapse>
        </AdminOnlyComponent>
        { isAdmin && <MenuItem
          key='menu-open'
          onClick={this.toggleSubMenu('statisticsMenuOpen')}>
          <ListItemIcon><BarChartIcon /></ListItemIcon>
          <ListItemText classes={{ primary: classes.subscriptionItem }}>
            <Trans i18nKey='app.menu.userMenu.statistics' />
            {statisticsMenuOpen
              ? <ExpandLessIcon style={{ marginLeft: 'auto' }}/>
              : <ExpandMoreIcon style={{ marginLeft: 'auto' }}/>
            }
          </ListItemText>
        </MenuItem>
        }
        { isAdmin && <Collapse in={statisticsMenuOpen}>
          <div style={{ borderBottom: '1px solid #efefef' }}>
            <MenuItem
              component={Link}
              className={classes.adminControl}
              to={getBasicRouteUrl(RouteName.STATISTICS)}>
              <ListItemText>Subscriptions</ListItemText>
            </MenuItem>
            <MenuItem
              className={classes.adminControl}
              component={Link}
              to={getBasicRouteUrl(RouteName.EXPIRED_SUBSCRIPTIONS)}>
              <ListItemText><Trans i18nKey='app.menu.userMenu.expired_subscriptions' /></ListItemText>
            </MenuItem>
            <MenuItem
              className={classes.adminControl}
              component={Link}
              to={getBasicRouteUrl(RouteName.SE_CRASHES)}>
              <ListItemText>SE crashes</ListItemText>
            </MenuItem>
          </div>
        </Collapse>
        }
        <MenuItem
          component={Link}
          to={getBasicRouteUrl(RouteName.TERMS)}>
          <ListItemIcon><TermsIcon /></ListItemIcon>
          <ListItemText><Trans i18nKey='app.menu.userMenu.terms' /></ListItemText>
        </MenuItem>
        <MenuItem onClick={() => { onLogout(); removeLidarmillCookies() }}>
          <ListItemIcon><RemoveCircleOutlineIcon /></ListItemIcon>
          <ListItemText><Trans i18nKey='app.menu.userMenu.logout' /></ListItemText>
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}

Header.propTypes = {
  forwardRef: PropTypes.any,
  totalReleases: PropTypes.number,
  loginAs: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isAdminOrPLSUser: PropTypes.bool,
  userId: PropTypes.string,
  activeUserId: PropTypes.string,
  activeCompanyId: PropTypes.string,
  logs: PropTypes.string,
  loggedAs: PropTypes.string,
  redirectURL: PropTypes.string,
  user: PropTypes.object,
  activeUser: PropTypes.object,
  loggedUser: PropTypes.object,
  match: PropTypes.object,
  loggedUserCompanyChildCompanies: PropTypes.object,
  location: PropTypes.object,
  breadcrumbs: PropTypes.object,
  currentProject: PropTypes.object,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  onLogout: PropTypes.func,
  onLoginAs: PropTypes.func,
  onSideMenuChange: PropTypes.func,
  onChangeHeaderHeight: PropTypes.func,
  // openRegisterSystemDialog: PropTypes.func,
}

Header.defaultPropTypes = {
  classes: {},
  match: {},
  history: {},
  breadcrumbsData: {
    breadcrumbs: [],
    basicUrl: '',
  },
}

const mapStateToProps = (state, props) => {
  /*
  const { location } = props
  const currentProject = getCurrentProject(state)
  const users = getUsers(state)
  const user = getLoggedUser(state)

  const match = getMatch(location.pathname)
  const url = match.url
  let breadcrumbs = []
  let title = ''
  // This stands for basically users, projects, statistics, ...etc
  const firstUrlSegment = url.split('/').filter(x => x)[0]
  if (firstUrlSegment === RouteName.PROJECTS) {
    const { user: projectOwnerUser = {} } = currentProject.project
    const ownerUser = users.find(_user => _user.id === projectOwnerUser.id)
    title = ownerUser && <Typography
        variant={'body1'}
        align={'right'}
        color={'inherit'}>
        {`Viewing ${ownerUser.first_name} ${ownerUser.last_name} ${ownerUser.email}`}
      </Typography>
      breadcrumbs = getBreadcrumbs({ state, ...props }, RouteName.PROJECTS, getTabName(match, RouteName.PROJECTS))
  }
  */

  const { activeUserId, activeCompanyId } = props
  const activeUser = getActiveUser(state) || {}
  const loggedUser = getLoggedUser(state)
  const userId = activeUserId || (loggedUser && loggedUser.id)
  const isAdmin = isUserAdmin(loggedUser)
  const isAdminOrPLSUser = isUserAdminOrPLSUser(loggedUser)
  const isUserCustomer = isCurrentUserCompanyCustomer(state)
  const isActiveUserCustomer = isUserCompanyCustomer(activeUser)
  const isEmployee = isCurrentUserCompanyEmployee(state)
  const redirectURL = getRedirectUrl(state)
  return {
    totalReleases: getTotalReleases(state),
    releases: getReleases(state),
    rovers: getCompanyRovers(state, getCompanyId(loggedUser)),
    userId,
    isAdmin,
    loggedUser,
    isAdminOrPLSUser,
    isActiveUserCustomer,
    isUserCustomer,
    isEmployee,
    user: loggedUser || {},
    loggedUserCompanyChildCompanies: getChildCompanies(state, getCompanyId(loggedUser)),
    childCompanies: getChildCompanies(state, activeCompanyId),
    redirectURL,
    loggedAs: state.users.get('loggedAs'),
  }
}

const mapDispatchToProps = dispatch => ({
  onLogout: () => dispatch(UsersActions.logout()),
  onLoginAs: (userId, userEmail) => () => dispatch(UsersActions.loginAs(userId, userEmail)),
  // openRegisterSystemDialog: () => dispatch(RoversActions.setRegisterSystemDialog(true)),
})

export default withRouter(
  withStyles(styles, { withTheme: true })(
    connect(mapStateToProps, mapDispatchToProps)(Header),
  ),
)
